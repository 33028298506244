import { FieldValues, Path, useFormContext } from 'react-hook-form';

import { renderUI } from '../util';
import { useInputGroupContextStrict } from './InputGroupContext';

export interface FeedbackProps<FormValues> {
  fromInputGroup?: boolean;
  name: Path<FormValues>;
}

export const Feedback = <FormValues extends FieldValues>({ fromInputGroup, name }: FeedbackProps<FormValues>) => {
  const useInputGroupContext = useInputGroupContextStrict();

  const isInsideInputGroupContext = useInputGroupContext !== undefined;

  // const [, meta] = useField(name);
  // const error =
  //   typeof meta.error === 'object' ? (meta.error as { key: string; params: Record<string, unknown> }) : undefined;

  // const message = error !== undefined ? t(`yup.${error.key}` as ResourceKeys, error.params) : meta.error;

  const { formState, getFieldState } = useFormContext<FormValues>();
  const { error } = getFieldState(name, formState);

  return renderUI({
    bs5: (
      <>
        {(!isInsideInputGroupContext || fromInputGroup) && error && (
          <div className="invalid-feedback">{error.message}</div>
        )}
      </>
    ),
  });
};
