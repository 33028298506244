export interface OptionProps
  extends React.DetailedHTMLProps<React.OptionHTMLAttributes<HTMLOptionElement>, HTMLOptionElement> {
  value?: string | number;
}

export const Option = ({ label, value, ...otherProps }: OptionProps): React.ReactElement => {
  return (
    <option {...otherProps} value={value}>
      {label ?? value}
    </option>
  );
};
