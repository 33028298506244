import classnames from 'classnames';
import React from 'react';
import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';

import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { useFormStaticContext } from '../FormContext';

// interface CommonProps<V> extends CommonFieldProps<HTMLInputElement, V> {
//   children?: React.ReactNode;
//   id: string;
//   inline?: boolean;
//   inputClassName?: string;
//   label?: string | React.ReactElement;
//   labelClassName?: string;
//   noMargin?: boolean;
//   showFeedback?: boolean;
//   value?: string;
// }

interface RadioCheckProps<FormValues extends FieldValues>
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    keyof RegisterOptions
  > {
  id: string;
  inline?: boolean;
  inputClassName?: string;
  label?: string;
  labelClassName?: string;
  name: Path<FormValues>;
  noMargin?: boolean;
  registerOptions?: RegisterOptions<FormValues>;
  showFeedback?: boolean;
  type: 'checkbox' | 'radio';
}

const RadioCheck = <FormValues extends FieldValues>({
  children,
  className,
  id,
  inline = false,
  inputClassName,
  label,
  labelClassName,
  name,
  noMargin = false,
  registerOptions,
  showFeedback,
  title,
  ...otherProps
}: RadioCheckProps<FormValues>) => {
  const { id: formId } = useFormStaticContext();
  const { formState, getFieldState, register } = useFormContext<FormValues>();
  const fieldState = getFieldState(name, formState);
  const isCustom = children !== undefined;

  const invalid = fieldState.invalid;

  return renderUI({
    bs5: (
      <div
        className={classnames(
          { 'form-check': label !== undefined && !isCustom },
          { 'form-check-inline': inline && !isCustom },
          { 'd-inline-block': isCustom },
          { 'me-2': isCustom && inline && !noMargin },
          className
        )}
        title={isCustom && typeof label === 'string' ? label : undefined}
      >
        <input
          {...register(name, {
            ...registerOptions,
            disabled: registerOptions?.disabled || undefined, // https://github.com/orgs/react-hook-form/discussions/10964
          })}
          {...otherProps}
          autoComplete={isCustom ? 'off' : undefined}
          className={classnames(
            'form-check-input',
            // { 'is-valid': b2xHelpers.isValid },
            { 'is-invalid': invalid },
            { 'btn-check': isCustom },
            inputClassName
          )}
          id={`${formId}-${name}-${id}`}
          title={title}
        />
        {(label || isCustom) && (
          <label
            className={classnames({ 'form-check-label': !isCustom }, { 'custom d-block': isCustom }, labelClassName)}
            htmlFor={`${formId}-${name}-${id}`}
            title={title}
          >
            {children ?? label}
          </label>
        )}
        {showFeedback && <Feedback name={name} />}
      </div>
    ),
  });
};

export interface CheckboxProps<FormValues extends FieldValues> extends Omit<RadioCheckProps<FormValues>, 'type'> {}
export const Checkbox = <FormValues extends FieldValues>(props: CheckboxProps<FormValues>): React.ReactElement => (
  <RadioCheck<FormValues> {...props} type="checkbox" />
);

export interface CheckboxesProps<FormValues extends FieldValues> {
  checkboxes: Array<CheckboxProps<FormValues>>;
}
export const Checkboxes = <FormValues extends FieldValues>({
  checkboxes,
}: CheckboxesProps<FormValues>): React.ReactElement => (
  <>
    {checkboxes.map((checkbox) => (
      <Checkbox<FormValues> key={checkbox.id} {...checkbox} />
    ))}
  </>
);

export interface RadioProps<FormValues extends FieldValues> extends Omit<RadioCheckProps<FormValues>, 'type'> {
  value: string;
}
export const Radio = <FormValues extends FieldValues>(props: RadioProps<FormValues>): React.ReactElement => (
  <RadioCheck<FormValues> {...props} type="radio" />
);

export interface RadiosProps<FormValues extends FieldValues> {
  radios: Array<RadioProps<FormValues>>;
}
export const Radios = <FormValues extends FieldValues>({ radios }: RadiosProps<FormValues>): React.ReactElement => (
  <>
    {radios.map((radio) => (
      <Radio<FormValues> key={radio.id} {...radio} />
    ))}
  </>
);
