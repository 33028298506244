import './ContestPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { useAppStaticContext } from '../AppContext';
import { BlockQuote } from '../BlockQuote';
import { Button } from '../Button';
import { Container } from '../Container';
import { ContestPageContentType } from '../contentTypes';
import { ContestGuestForm } from '../forms/ContestGuestForm';
import { ContestLoggedForm } from '../forms/ContestLoggedForm';
import { t } from '../i18n/i18n';
import { Section } from '../Section';
import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';
import { SocialSharing } from '../SocialSharing';
import { Page } from './Page';

export const ContestPage = () => {
  const page = b2x.usePage<ContestPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const ambassadorSliderNextButton = React.useRef<HTMLButtonElement>(null);
  const ambassadorSliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const { session } = b2x.useAppContext();
  const { showAccountOffcanvas } = useAppStaticContext();

  const [formSectionRef, scrollToFormSectionRef] = b2x.useScrollTo();

  const [registrationSuccess, setRegistrationSuccess] = React.useState<boolean>(false);

  const handleRegistrationSuccess = React.useCallback(() => {
    setRegistrationSuccess(true);
  }, [setRegistrationSuccess]);

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="contest-page">
        {page?.content?.body.contestMainSection?.img && (
          <Section className="contest-main-section mb-5">
            <b2x.ImageFromContent {...page.content.body.contestMainSection.img} fluid />
            <div className="text-center mt-5 d-xl-none">
              <Button onClick={scrollToFormSectionRef}>
                {session?.userLogged ? t('contest.subscribe') : t('contest.subscribeLogin')}
              </Button>
            </div>
          </Section>
        )}
        {page?.content?.body.contestClosed && (
          <Section className="contest-winners-section mb-5" container="xxl">
            <h3 className="h1 text-uppercase fw-bold text-center mb-0">
              {page.content.body.contestWinnersSection?.title}
            </h3>
            <h3 className="h1 text-uppercase fw-bold text-center text-primary mb-5">
              {page.content.body.contestWinnersSection?.subtitle}
            </h3>
            {page.content.body.contestWinnersSection?.ambassadorList &&
              page.content.body.contestWinnersSection.ambassadorList.length > 0 && (
                <b2x.Row>
                  {page.content.body.contestWinnersSection.ambassadorList.map((winner, index) => (
                    <b2x.Col className="col-12 col-md-6 mb-5" key={winner.title}>
                      <div className="img-container">
                        {winner.img && <b2x.ImageFromContent {...winner.img} fluid />}
                      </div>
                      <div className="text-container position-relative px-4 mt-n5">
                        <div className="bg-black position-relative text-white p-4 pb-5">
                          <div className="fw-semi-bold">{winner.title && winner.title}</div>
                          <div className="fw-light">{winner.description && b2x.formatHtml(winner.description)}</div>
                        </div>
                      </div>
                    </b2x.Col>
                  ))}
                </b2x.Row>
              )}
          </Section>
        )}
        <Section className="contest-description-section mb-5">
          <div className="position-relative">
            <div className="contest-media-container">
              {page?.content?.body.contestDescriptionSection?.video ? (
                <div className="video-container position-relative mb-5 mb-xl-0">
                  <Container>
                    <b2x.Row>
                      <b2x.Col size={{ md: 6, xs: 12 }}>
                        <b2x.DeprecatedVideoFromContent
                          {...page.content.body.contestDescriptionSection.video}
                          autoplay
                          controls
                          loop
                        />
                      </b2x.Col>
                    </b2x.Row>
                  </Container>
                </div>
              ) : (
                <div className="img-container">
                  {page?.content?.body.contestDescriptionSection?.img && (
                    <b2x.ImageFromContent {...page.content.body.contestDescriptionSection.img} fluid />
                  )}
                </div>
              )}
            </div>
            <div className="text-container flex-column d-flex align-items-center justify-content-center">
              <div className="w-100 text-center text-xl-end mb-4">
                <SocialSharing />
              </div>
              <div className="bg-white shadow mb-5 ps-3 pe-5 py-5">
                <BlockQuote>{b2x.formatHtml(page?.content?.body.contestDescriptionSection?.description)}</BlockQuote>
              </div>
              {!page?.content?.body.contestClosed && (
                <Button onClick={scrollToFormSectionRef}>
                  {session?.userLogged ? t('contest.subscribe') : t('contest.subscribeLogin')}
                </Button>
              )}
            </div>
          </div>
        </Section>
        {!page?.content?.body.contestClosed && (
          <Section className="contest-instructions-section text-center mb-5" container="xl">
            {page?.content?.body.contestInstructionSection?.title && (
              <h3 className="h1 text-uppercase fw-bold mb-5">{page.content.body.contestInstructionSection.title}</h3>
            )}
            {page?.content?.body.contestInstructionSection?.instructionList && (
              <b2x.EqualHeight>
                <div className="slider-container mb-4">
                  <Slider
                    autoHeight
                    pagination
                    responsive={{
                      lg: {
                        slidesPerView: 3,
                      },
                      md: {
                        slidesPerView: 2,
                      },
                      xl: {
                        slidesPerView: 4,
                      },
                    }}
                    slidesPerView={1}
                    spaceBetween={20}
                  >
                    {page.content.body.contestInstructionSection.instructionList.map((slide, index) => (
                      <SwiperSlide key={slide.description}>
                        <b2x.EqualHeightElement key={slide.description} name="instruction-slide">
                          <div className="info-box h-100 position-relative">
                            <div className="image-container position-absolute">
                              {slide.img && <b2x.ImageFromContent {...slide.img} fluid format={288} />}
                            </div>
                            <div className="description-container h-100 d-flex align-items-center border border-primary border-5 pt-5 px-3 pb-3 px-3">
                              <div>{b2x.formatHtml(slide.description)}</div>
                            </div>
                          </div>
                        </b2x.EqualHeightElement>
                      </SwiperSlide>
                    ))}
                  </Slider>
                </div>
              </b2x.EqualHeight>
            )}
            <div>
              {page?.content?.body.contestInstructionSection?.regulationUrlFile && (
                <div className="mb-4">
                  {/* eslint-disable-next-line react/forbid-elements */}
                  <a
                    className="text-black"
                    href={page.content.body.contestInstructionSection.regulationUrlFile}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {t('contest.regulation')}
                  </a>
                </div>
              )}
              <Button onClick={scrollToFormSectionRef}>
                {session?.userLogged ? t('contest.subscribe') : t('contest.subscribeLogin')}
              </Button>
            </div>
          </Section>
        )}
        <Section className="contest-ambassador-section mb-5">
          {page?.content?.body.contestAmbassadorSection?.title && (
            <h3 className="h1 text-uppercase fw-bold text-center mb-5">
              {page.content.body.contestAmbassadorSection.title}
            </h3>
          )}
          {page?.content?.body.contestAmbassadorSection?.ambassadorList && (
            <div className="slider-container">
              <Slider
                loop
                navigation
                responsive={{
                  lg: {
                    slidesPerView: 3.15,
                  },
                  md: {
                    slidesPerView: 2.15,
                  },
                  xl: {
                    slidesPerView: 4,
                  },
                }}
                sliderNextEl={ambassadorSliderNextButton}
                sliderPrevEl={ambassadorSliderPrevButton}
                slidesPerView={1.15}
                spaceBetween={20}
              >
                {page.content.body.contestAmbassadorSection.ambassadorList.map((slide, index) => (
                  <SwiperSlide key={slide.title}>
                    <div className="img-container">{slide.img && <b2x.ImageFromContent {...slide.img} fluid />}</div>
                    <div className="text-container position-relative px-4 mt-n5">
                      <div className="bg-black position-relative text-white p-4 pb-5">
                        <div className="fw-semi-bold">{slide.title && slide.title}</div>
                        <div className="fw-light">{slide.description && b2x.formatHtml(slide.description)}</div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Slider>
              <SliderButton direction="left" innerRef={ambassadorSliderPrevButton} size="large" />
              <SliderButton direction="right" innerRef={ambassadorSliderNextButton} size="large" />
            </div>
          )}
        </Section>
        {!page?.content?.body.contestClosed && (
          <div ref={formSectionRef}>
            <Section className="contest-form-section bg-lighter mb-5" container="xl" withDots>
              {!registrationSuccess ? (
                session?.userLogged ? (
                  <>
                    <ContestLoggedForm
                      onSuccess={handleRegistrationSuccess}
                      title={page?.content?.body.contestFormSection?.title}
                    />
                  </>
                ) : (
                  <>
                    {page?.content?.body.contestFormSection?.title && (
                      <h3 className="h1 text-uppercase fw-bold text-center mb-0">
                        {page.content.body.contestFormSection.title}
                      </h3>
                    )}
                    <h4 className="mb-5 fw-semi-bold text-center">
                      {t('contest.registration.guestTitle')}
                      <Button
                        className="fs-4 fw-semi-bold text-decoration-underline"
                        label={t('contest.registration.login')}
                        onClick={showAccountOffcanvas}
                        variant="outline-link"
                      />
                    </h4>
                    <ContestGuestForm onSuccess={handleRegistrationSuccess} />
                  </>
                )
              ) : (
                <div className="text-center">
                  <h3 className="h1 text-uppercase fw-bold mb-4">
                    {t('contest.registration.messages.registrationSuccess')}
                  </h3>
                  <h4 className="mb-5 fw-light">{t('contest.registration.messages.emailNotification')}</h4>
                  <b2x.router.Link className="btn btn-primary text-uppercase" to={'/'}>
                    {t('contest.registration.cta.toCollection')}
                  </b2x.router.Link>
                </div>
              )}
            </Section>
          </div>
        )}
        <Section className="contest-crew-section" container="fluid">
          <h3 className="h1 text-uppercase fw-bold text-center mb-0">
            {page?.content?.body.contestCrewSection?.title}
          </h3>
          <h3 className="text-center text-primary mb-5">{page?.content?.body.contestCrewSection?.subtitle}</h3>
          {page?.content?.body.contestCrewSection?.crewList && (
            <b2x.Row cols={{ lg: 4, md: 2, sm: 2, xl: 4, xs: 2, xxl: 4 }}>
              {page.content.body.contestCrewSection.crewList.map((crew, index) => (
                <div className="col mb-3" key={crew.contentSectionId}>
                  <div className="img-container mb-2">{crew.img && <b2x.ImageFromContent {...crew.img} fluid />}</div>
                  <div className="text-container fw-semi-bold">{crew.title}</div>
                </div>
              ))}
            </b2x.Row>
          )}
        </Section>
      </div>
    </Page>
  );
};
