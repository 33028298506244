import './SliderE.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { IconName } from '../Icon';
import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';

export interface SliderEProps {
  aspectRatio?: number | b2x.AspectRatio;
  sliderList?: Array<b2x.ImageProps>;
}

export const SliderE = ({ aspectRatio, sliderList }: SliderEProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);

  // Poichè lo Slider E graficamente funziona esclusivamente con un minimo di 3 immagini,
  // bisogna effettuare un controllo nell'eventualità vengano passate meno immagini del dovuto.
  // Per questo è stata creata questa useMemo che nel caso in cui vengono passate 1 o 2 immagini,
  // ne duplica il contenuto per far sì che nella lista ci siano almeno 3 immagini.

  const sliderListUpdate = React.useMemo(() => {
    const tempSliderList = sliderList;

    if (sliderList?.length === 1) {
      tempSliderList?.push(...sliderList, ...sliderList);
    } else if (sliderList?.length === 2) {
      tempSliderList?.push(...sliderList);
    }

    return tempSliderList;
  }, [sliderList]);

  return (
    <b2x.Lightbox<IconName>
      arrowNextIcon={{ color: 'white', name: 'small-arrow-right', size: 40 }}
      arrowPrevIcon={{ color: 'white', name: 'small-arrow-left', size: 40 }}
      // closeIcon={{ color: 'white', name: 'delete', size: 40 }}
      // zoomIcon={{ color: 'white', name: 'search', size: 40 }}
    >
      <Slider
        className="SliderE"
        loop
        navigation
        responsive={{
          md: {
            slidesPerView: 2,
          },
          xl: {
            slidesPerView: 3,
          },
        }}
        sliderNextEl={sliderNextButton}
        sliderPrevEl={sliderPrevButton}
        slidesPerView={1}
        spaceBetween={16}
      >
        <SliderButton direction="left" innerRef={sliderPrevButton} size="large" />
        <SliderButton direction="right" innerRef={sliderNextButton} size="large" />
        {sliderListUpdate?.map(
          (image, index) =>
            image.src &&
            typeof image.src === 'string' && (
              <SwiperSlide key={image.src}>
                <b2x.LightboxItem src={image.src}>
                  <b2x.Image aspectRatio={aspectRatio} format={1600} src={image.src} />
                </b2x.LightboxItem>
              </SwiperSlide>
            )
        )}
      </Slider>
    </b2x.Lightbox>
  );
};
