import { ProductApiDto, SkuApiDto } from '@b2x/storefront-api-js-client/src/dto';
import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import { ArrayElement } from 'type-fest/source/internal';

import { useProductsApi } from '../api/useProductsApi';
import { appConfig } from '../config';
import { t } from '../i18n/i18n';
import { useWishlistButtonHelper } from '../useWishlistButtonHelper';
import { NumberInputProps, TextInputProps } from './fields/Input';
import { OptionProps } from './fields/Option';
import { RadioProps } from './fields/RadioCheck';
import { SelectProps } from './fields/Select';
import { TextAreaProps } from './fields/TextArea';
import { BaseUseFormProps, FormButtonProps, FormProps, isResetButtonDisabled, isSubmitButtonDisabled } from './Form';
import { FormGroupProps } from './FormGroup';
import { useForm } from './useForm';

export interface UseAddToCartFormProps
  extends Omit<BaseUseFormProps<FormValues, ValidationSchemaSelector>, 'defaultValues'> {
  imageSkuVariantAttributeTypeCode?: string;
  preselectedSkuId?: string;
  product: ProductApiDto;
  scope: 'product' | 'tile';
}

interface FormValues {
  giftCard?: GiftCardFormValues;
  // price: number; // togliere e derivare
  productVariantId: string;
  quantity: number;
  skuAttributes: Record<string, string>;
  skuId: string;
}

interface GiftCardFormValues {
  message: string;
  receiverEmail: string;
  receiverName: string;
  senderName: string;
}

interface ValidationSchemaSelector {}

interface FieldsHelper {
  buttons: {
    availabilityEmailNotification: FormButtonProps;
    cancel?: FormButtonProps;
    quantity: {
      decrease: FormButtonProps;
      increase: FormButtonProps;
    };
    reset: FormButtonProps;
    submit: FormButtonProps;
  };
  giftCard?: {
    message: { formGroup: FormGroupProps; textArea: TextAreaProps<FormValues> };
    receiverEmail: { formGroup: FormGroupProps; textInput: TextInputProps<FormValues> };
    receiverName: { formGroup: FormGroupProps; textInput: TextInputProps<FormValues> };
    senderName: { formGroup: FormGroupProps; textInput: TextInputProps<FormValues> };
  };
  productVariants: {
    formFields: Array<{ productVariant: ProductApiDto; radio: RadioProps<FormValues> }>;
    formGroup: FormGroupProps;
  };
  quantity: { formGroup: FormGroupProps; numberInput: NumberInputProps<FormValues>; select: SelectProps<FormValues> };
  // skuAttributes: Array<{
  //   attribute: {
  //     title: string;
  //     typeCode: string;
  //     value?: string;
  //   };
  //   formGroup: FormGroupProps;
  //   // radios: RadiosProps;
  //   radios: Array<{ radio: RadioProps<FormValues>; skus: Array<SkuApiDto> }>;
  //   select: SelectProps<FormValues>;
  // }>;
  skus: {
    formFields: Array<{ option: OptionProps; radio: RadioProps<FormValues>; sku: SkuApiDto }>;
    formGroup: FormGroupProps;
    select: SelectProps<FormValues>;
  };
}

export const useAddToCartForm = ({
  className,
  imageSkuVariantAttributeTypeCode,
  onCancel,
  onSuccess,
  preselectedSkuId,
  product,
  scope,
  singleColumn,
  validationSchemaSelector,
}: UseAddToCartFormProps) => {
  const productVariants = React.useMemo<Array<ProductApiDto>>(
    () => [{ ...product, variantProducts: undefined }, ...(product.variantProducts ?? [])],
    [product]
  );

  const addToCartForm = useForm<FormValues>({
    defaultValues: {
      productVariantId: product.id,
      // quantity: 1,
    },
  });

  const handleSubmit: SubmitHandler<FormValues> = React.useCallback((values) => {
    console.log(values);
  }, []);

  const formHelper = React.useMemo<FormProps<FormValues>>(
    () =>
      ({
        className: 'add-to-cart-form',
        form: addToCartForm,
        onSubmit: handleSubmit,
      } satisfies FormProps<FormValues>),
    [addToCartForm, handleSubmit]
  );

  const [selectedProductVariant, setSelectedProductVariant] = React.useState<ProductApiDto>(product);
  const [selectedSku, setSelectedSku] = React.useState<SkuApiDto>();

  const { handleWishlistButtonClick, inWishlist } = useWishlistButtonHelper({
    product: selectedProductVariant,
    sku: selectedSku,
  });

  const isAtLeastOneSkuAvailable = React.useMemo(() => {
    return selectedProductVariant.skus?.some((sku) => sku.state === 'AVAILABLE');
  }, [selectedProductVariant.skus]);

  const { getProduct } = useProductsApi();

  const handleProductVariantIdChange = React.useCallback(
    (_productVariantId: string) => {
      getProduct(_productVariantId, {
        populate:
          scope === 'product'
            ? { ...appConfig.api?.productPopulate, variantProducts: false }
            : { ...appConfig.api?.productTilePopulate, variantProducts: false },
      }).then((response) => {
        setSelectedProductVariant(response.data);
      });
    },
    [getProduct, scope]
  );

  // const productVariantId = addToCartForm.watch('productVariantId');
  // React.useEffect(() => {
  //   handleProductVariantIdChange(productVariantId);
  // }, [handleProductVariantIdChange, productVariantId]);

  const { watch } = addToCartForm;
  React.useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      console.log(value, name, type);
      if (name === 'productVariantId' && type === 'change' && value.productVariantId !== undefined) {
        handleProductVariantIdChange(value.productVariantId);
      }
    });
    return () => subscription.unsubscribe();
  }, [handleProductVariantIdChange, watch]);

  const fieldsHelper = React.useMemo<FieldsHelper>(
    () =>
      ({
        buttons: {
          availabilityEmailNotification: {
            label: inWishlist
              ? t('form.addToCartForm.buttons.availabilityEmailNotification.remove')
              : t('form.addToCartForm.buttons.availabilityEmailNotification.addLongLabel'),
            onClick: handleWishlistButtonClick,
          },
          cancel: onCancel
            ? {
                label: t('form.addToCartForm.buttons.cancel'),
                onClick: onCancel,
                type: 'button',
                variant: appConfig.form?.buttons.cancel?.defaultVariant,
              }
            : undefined,
          quantity: {
            decrease: {
              // disabled: formik.values.quantity === 1,
              label: '-',
              // onClick: () => {
              //   const previousQuantity = getFormikNumberValue(formik.values.quantity);
              //   if (previousQuantity) {
              //     formik.setFieldValue('quantity', previousQuantity - 1);
              //   }
              // },
            },
            increase: {
              // disabled: formik.values.quantity === appConfig.productMaxQuantity,
              label: '+',
              // onClick: () => {
              //   const previousQuantity = getFormikNumberValue(formik.values.quantity);
              //   if (previousQuantity) {
              //     formik.setFieldValue('quantity', previousQuantity + 1);
              //   }
              // },
            },
          },
          reset: {
            disabled: isResetButtonDisabled(),
            label: t('form.addToCartForm.buttons.reset'),
            type: 'reset',
            variant: appConfig.form?.buttons.cancel?.defaultVariant,
          },
          submit: {
            disabled: isSubmitButtonDisabled() || !isAtLeastOneSkuAvailable,
            label: isAtLeastOneSkuAvailable
              ? t('form.addToCartForm.buttons.submit')
              : t('form.addToCartForm.buttons.submitButNotAvailable'),
            type: 'submit',
            variant: appConfig.form?.buttons.submit?.defaultVariant,
          },
        },
        giftCard: product.giftCard
          ? {
              message: {
                formGroup: { label: t('form.addToCartForm.giftCard.message.label'), names: ['giftCard.message'] },
                textArea: { name: 'giftCard.message', rows: 3 },
              },
              receiverEmail: {
                formGroup: {
                  label: t('form.addToCartForm.giftCard.receiverEmail.label'),
                  names: ['giftCard.receiverEmail'],
                  required: true,
                },
                textInput: { name: 'giftCard.receiverEmail', registerOptions: { required: true }, type: 'email' },
              },
              receiverName: {
                formGroup: {
                  label: t('form.addToCartForm.giftCard.receiverName.label'),
                  names: ['giftCard.receiverName'],
                  required: true,
                },
                textInput: {
                  name: 'giftCard.receiverName',
                  registerOptions: { required: true },
                },
              },
              senderName: {
                formGroup: {
                  label: t('form.addToCartForm.giftCard.senderName.label'),
                  names: ['giftCard.senderName'],
                  required: true,
                },
                textInput: {
                  name: 'giftCard.senderName',
                  registerOptions: { required: true },
                },
              },
            }
          : undefined,
        productVariants: {
          formFields: productVariants.map(
            (productVariant) =>
              ({
                productVariant: productVariant,
                radio: {
                  id: `productvariant-${productVariant.id}`,
                  label: `${productVariant.name} (${productVariant.id})`,
                  name: 'productVariantId',
                  registerOptions: {
                    // onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                    //   handleProductVariantIdChange(e.target.value);
                    // },
                    required: true,
                  },
                  value: productVariant.id,
                },
              } satisfies ArrayElement<FieldsHelper['productVariants']['formFields']>)
          ),
          formGroup: {
            label: t('form.addToCartForm.productVariants'),
            names: ['productVariantId'],
            omitForAttribute: true,
          },
        },

        quantity: {
          formGroup: { label: t('form.addToCartForm.quantity.label'), names: ['quantity'] },
          numberInput: {
            name: 'quantity',
            placeholder: t('form.addToCartForm.quantity.placeholder'),
            registerOptions: { max: appConfig.productMaxQuantity, min: 1, required: true, valueAsNumber: true },
          },
          select: {
            name: 'quantity',
            options: [...Array(appConfig.productMaxQuantity)].map(
              (quantity, index) =>
                ({
                  label: (index + 1).toString(),
                  value: (index + 1).toString(),
                } satisfies ArrayElement<FieldsHelper['quantity']['select']['options']>)
            ),
            placeholder: t('form.addToCartForm.quantity.placeholder'),
            registerOptions: { max: appConfig.productMaxQuantity, min: 1, required: true, valueAsNumber: true },
          },
        },
        // skuAttributes: Object.entries(skuVariantAttributesMap).map(([attributeTypeCode, items]) => ({
        //   attribute: {
        //     title: items[0].attribute.title ?? '',
        //     typeCode: items[0].attribute.typeCode,
        //     value:
        //       formik.values.skuAttributes[attributeTypeCode] !== ''
        //         ? formik.values.skuAttributes[attributeTypeCode]
        //         : undefined,
        //   },
        //   formGroup: { label: items[0].attribute.title, names: [] },
        //   radios: items.map((item) => ({
        //     radio: {
        //       className: getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes),
        //       disabled:
        //         !appConfig.enableProductAvailabilityEmailNotification &&
        //         (getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'NOT_AVAILABLE' ||
        //           getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'AVAILABLE_SOON'),
        //       id: `sku-attribute-${item.attribute.valueCode}`,
        //       label: item.attribute.value ?? '',
        //       name: `skuAttributes.${attributeTypeCode}`,
        //       value: item.attribute.valueCode ?? '',
        //     },
        //     skus: item.skus,
        //   })),
        //   // radios: {
        //   //   radios: attributes.map((attribute) => ({
        //   //     disabled:
        //   //       getSkuAttributeAvailability(attribute, formik.values.skuAttributes) === 'NOT_AVAILABLE' ||
        //   //       getSkuAttributeAvailability(attribute, formik.values.skuAttributes) === 'AVAILABLE_SOON',
        //   //     id: `sku-attribute-${attribute.value}`,
        //   //     label: attribute.value ?? '',
        //   //     name: `skuAttributes.${slugify(attributeName)}`,
        //   //     value: attribute.value ?? '',
        //   //   })),
        //   // },
        //   select: {
        //     includeEmptyOption: false,
        //     name: `skuAttributes.${attributeTypeCode}`,
        //     options: items.map((item) => ({
        //       className: getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes),
        //       disabled:
        //         !appConfig.enableProductAvailabilityEmailNotification &&
        //         (getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'NOT_AVAILABLE' ||
        //           getSkuAttributeAvailability(item.attribute, formik.values.skuAttributes) === 'AVAILABLE_SOON'),
        //       id: `sku-attribute-${item.attribute.valueCode}`,
        //       label:
        //         attributeTypeCode === 'Gift_card_valore' && item.attribute.value
        //           ? formatCurrency(parseInt(item.attribute.value))
        //           : item.attribute.value ?? '',
        //       value: item.attribute.valueCode ?? '',
        //     })),
        //     placeholder: t(`form.addToCartForm.skuAttributes.${attributeTypeCode}.placeholder` as ResourceKeys),
        //   },
        // })),
        skus: {
          formFields: selectedProductVariant.skus
            ? selectedProductVariant.skus.map(
                (sku) =>
                  ({
                    option: {
                      className: sku.state,
                      disabled:
                        !appConfig.enableProductAvailabilityEmailNotification &&
                        (sku.state === 'NOT_AVAILABLE' || sku.state === 'AVAILABLE_SOON'),
                      label: sku.name,
                      value: sku.id,
                    },
                    radio: {
                      className: sku.state,
                      id: `sku-${sku.id}`,
                      label: `${sku.name} (${sku.id})`,
                      name: 'skuId',
                      registerOptions: {
                        disabled:
                          !appConfig.enableProductAvailabilityEmailNotification &&
                          (sku.state === 'NOT_AVAILABLE' || sku.state === 'AVAILABLE_SOON'),
                        required: true,
                      },
                      value: sku.id,
                    },
                    sku: sku,
                  } satisfies ArrayElement<FieldsHelper['skus']['formFields']>)
              )
            : [],
          formGroup: { label: t('form.addToCartForm.skus.label'), names: ['skuId'], omitForAttribute: true },
          select: {
            includeEmptyOption: false,
            name: 'skuId',
            placeholder: t('form.addToCartForm.skus.placeholder'),
          },
        },
      } satisfies FieldsHelper),
    [
      handleWishlistButtonClick,
      inWishlist,
      isAtLeastOneSkuAvailable,
      onCancel,
      product.giftCard,
      productVariants,
      selectedProductVariant.skus,
    ]
  );

  return { addToCartForm, fieldsHelper, formHelper, selectedProductVariant, selectedSku };
};
