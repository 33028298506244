import { b2x } from '@b2x/react/src';
import React from 'react';

import { Accordion } from '../Accordion';
import { Container } from '../Container';
import { TemplatePageContentType } from '../contentTypes';
import { CustomRadio } from '../CustomRadio';
import { Dropdown } from '../Dropdown';
import { iconsNames } from '../Icon';
import { ListA } from '../ListA';
import { ListB } from '../ListB';
import { ListC } from '../ListC';
import { PageHeader } from '../PageHeader';
import { Section } from '../Section';
import { SectionA } from '../SectionA';
import { SliderA } from '../slider/SliderA';
import { SliderB } from '../slider/SliderB';
import { SliderC } from '../slider/SliderC';
import { SliderD } from '../slider/SliderD';
import { Page } from './Page';

export const TemplatePage = () => {
  const page = b2x.usePage<TemplatePageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const { sortingOptions } = b2x.useSortingOptions([
    { orderBy: 'PRIORITY', orderingType: 'ASC' },
    { orderBy: 'PRIORITY', orderingType: 'DESC' },
    { orderBy: 'NAME', orderingType: 'ASC' },
    { orderBy: 'NAME', orderingType: 'DESC' },
    { orderBy: 'PRICE', orderingType: 'ASC' },
    { orderBy: 'PRICE', orderingType: 'DESC' },
    { orderBy: 'ID', orderingType: 'ASC' },
    { orderBy: 'ID', orderingType: 'DESC' },
  ]);

  // const { getCart } = b2x.useCartApi();

  // React.useEffect(() => {
  //   getCart({
  //     populate: {
  //       // alerts: true,
  //       // availableCoupons: true,
  //       // // billingAddress: true,
  //       // depositPayments: true,
  //       // offers: true,
  //       // packs: {
  //       //   alerts: true,
  //       //   cartSkus: {
  //       //     alerts: true,
  //       //     // giftCard: true,
  //       //     offers: true,
  //       //     sku: {
  //       //       alternativeImages: true,
  //       //       attributes: true,
  //       //       // closestExpiringDate: true,
  //       //       measurement: true,
  //       //       price: true,
  //       //       product: {
  //       //         brand: true,
  //       //       },
  //       //     },
  //       //   },
  //       // },
  //       // // paymentMethod: true,
  //       // // shippingAddress: true,
  //       // shippingProfile: true,
  //     },
  //   }).then((response) => {
  //     b2x.filler.fillCart(response.data, {
  //       alerts: true,
  //       availableCoupons: true,
  //       // billingAddress: true,
  //       depositPayments: true,
  //       offers: true,
  //       packs: {
  //         alerts: true,
  //         cartSkus: {
  //           alerts: true,
  //           // giftCard: true,
  //           offers: true,
  //           sku: {
  //             alternativeImages: true,
  //             attributes: true,
  //             // closestExpiringDate: true,
  //             measurement: true,
  //             price: true,
  //             product: {
  //               brand: true,
  //             },
  //           },
  //         },
  //       },
  //       // paymentMethod: true,
  //       // shippingAddress: true,
  //       shippingProfile: true,
  //     });
  //   });
  // }, [getCart]);

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {page && (
        <PageHeader
          breadcrumb
          subtitle={page.description ?? 'Lorem ipsum dolor sit amet decovir facte'}
          title={page.name}
        />
      )}

      <TemplateSection title="Top suggestions">
        <b2x.template.TopSuggestions />
      </TemplateSection>

      <TemplateSection title="Grid System">
        <b2x.template.GridSystem />
      </TemplateSection>

      <TemplateSection title="Typography">
        <b2x.template.Typography
          variants={[
            { className: 'display-1', name: 'Display 1' },
            { className: 'display-5', name: 'Display 5' },
            { className: 'h1', name: 'Heading 1' },
            { className: 'h2', name: 'Heading 2' },
            { className: 'h3', name: 'Heading 3' },
            { className: 'h4', name: 'Heading 4' },
            { className: 'h5', name: 'Heading 5' },
            { className: 'h6', name: 'Heading 6' },
            { className: 'fw-bold', name: 'Bold text' },
            { className: 'fw-semi-bold', name: 'Semi bold text' },
            { className: 'fw-normal', name: 'Normal text' },
            { className: 'fw-light', name: 'Light text' },
            { className: 'fw-thin', name: 'Thin text' },
            { className: 'small ', name: 'Small text' },
            { className: 'extra-small', name: 'Extra small text' },
          ]}
        />
      </TemplateSection>

      <TemplateSection title="Icons">
        <b2x.template.Icons names={iconsNames} />
      </TemplateSection>

      <TemplateSection title="Spacing">
        <b2x.template.Spacing />
      </TemplateSection>

      <TemplateSection title="Colors">
        <b2x.template.Colors
          colors={[
            { background: 'primary', text: 'white' },
            { background: 'secondary', text: 'white' },
            { background: 'dark', text: 'white' },
            { background: 'light', text: 'black' },
            { background: 'lighter', text: 'black' },
            { background: 'danger', text: 'white' },
            { background: 'offer', text: 'white' },
            { background: 'black', text: 'white' },
          ]}
        />
      </TemplateSection>

      <TemplateSection title="Form">
        <Container>
          <b2x.ExampleForm />
        </Container>
      </TemplateSection>

      <TemplateSection title="Buttons">
        <b2x.template.Buttons
          backgrounds={['white']}
          iconsOrLabels={['onlyLabel', 'onlyIcon', 'IconLeftAndLabel', 'IconRightAndLabel']}
          sizes={[undefined]}
          variants={['primary', 'danger', 'offer', 'dark', 'secondary', 'light', 'lighter', 'link']}
        />
      </TemplateSection>

      <TemplateSection title="Dropdowns">
        <Container>
          <div className="row">
            <b2x.template.Dropdowns
              hideAlignments
              hideAutoClose
              hideComponent
              hideDarkVariant
              hideDirections
              hideOffsets
              sizes={[undefined]}
              variants={['primary', 'danger', 'offer', 'dark', 'secondary', 'light', 'lighter', 'link']}
            />
          </div>
        </Container>
      </TemplateSection>

      <TemplateSection title="Videos">
        <b2x.template.Videos />
      </TemplateSection>

      <TemplateSection title="Table">
        <Container>
          <b2x.Table border="light" className="border" colNumber={10} responsive xAlignment="center">
            <b2x.TableHead variant="light">
              <tr>
                {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((th) => (
                  <th key={th}>th{th}</th>
                ))}
              </tr>
            </b2x.TableHead>
            <b2x.TableBody>
              {[1, 2, 3, 4, 5].map((tr) => (
                <tr key={tr}>
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((td) => (
                    <td key={td}>
                      tr{tr} td{td}
                    </td>
                  ))}
                </tr>
              ))}
            </b2x.TableBody>
          </b2x.Table>
        </Container>
      </TemplateSection>

      <TemplateSection title="Tabs">
        <Container>
          <b2x.template.Tabs />
        </Container>
      </TemplateSection>

      <TemplateSection title="Accordion">
        <Container>
          <div className="row">
            <div className="col-lg-6 mb-5">
              <h5>Default</h5>
              <Accordion id="AccordionExample">
                <b2x.AccordionItem id="One" title="Title ONE">
                  <b2x.LoremIpsum />
                </b2x.AccordionItem>
                <b2x.AccordionItem id="Two" title="Title Two">
                  <b2x.LoremIpsum />
                </b2x.AccordionItem>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <h5>Default (variant-b, sempre aperto)</h5>
              <Accordion alwaysOpened id="AccordionExample2" variant="b">
                <b2x.AccordionItem id="Three" title="Title ONE">
                  <b2x.LoremIpsum />
                </b2x.AccordionItem>
                <b2x.AccordionItem id="Four" title="Title Two">
                  <b2x.LoremIpsum />
                </b2x.AccordionItem>
              </Accordion>
            </div>
          </div>
        </Container>
      </TemplateSection>

      <TemplateSection title="Lists">
        <Container>
          <div className="mb-4">
            <h4>List A</h4>
            <ListA
              items={[
                { cta: 'https://www.google.com', label: 'Lorem' },
                { cta: 'https://www.google.com', label: 'ipsum' },
                { cta: 'https://www.google.com', label: 'dolor' },
                { cta: 'https://www.google.com', label: 'sit' },
                { cta: 'https://www.google.com', label: 'amet' },
              ]}
            />
          </div>
          <div className="bg-lighter p-3">
            <div className="row">
              <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-3 mb-sm-0">
                <h4 className="">List B</h4>
                <ListB
                  items={[
                    { icon: 'profile', label: 'Lorem', link: 'https://www.google.com' },
                    { icon: 'wishlist', label: 'ipsum', link: 'https://www.google.com' },
                    { icon: 'cart', label: 'dolor', link: 'https://www.google.com' },
                    { icon: 'email', label: 'sit', link: 'https://www.google.com' },
                    { icon: 'sharing', label: 'amet', link: 'https://www.google.com' },
                  ]}
                />
              </div>
              <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3">
                <h4 className="">List C</h4>
                <ListC
                  items={[
                    { label: 'Lorem', link: 'https://www.google.com' },
                    { label: 'Ipsum', link: 'https://www.google.com' },
                    { label: 'Dolor', link: 'https://www.google.com' },
                    { label: 'Sit', link: 'https://www.google.com' },
                    { label: 'Amet', link: 'https://www.google.com' },
                  ]}
                  title="Servizio Clienti"
                />
              </div>
            </div>
          </div>
        </Container>
      </TemplateSection>

      <TemplateSection title="Custom Radio">
        <Container>
          <b2x.Form initialValues={{}}>
            <b2x.FormGroup names={[]} omitForAttribute>
              <b2x.Radio id="radio1" inline label="Radio 1" name="customRadios" value="radio1">
                <CustomRadio />
              </b2x.Radio>
              <b2x.Radio id="radio2" inline label="Radio 2" name="customRadios" value="radio2">
                <CustomRadio />
              </b2x.Radio>
              <b2x.Radio disabled id="radio3" inline label="Radio disabled" name="customRadios" value="radio3">
                <CustomRadio />
              </b2x.Radio>
              <b2x.Radio
                id="radio4"
                inline
                inputClassName="is-invalid"
                label="Radio with error"
                name="customRadios"
                value="radio4"
              >
                <CustomRadio />
              </b2x.Radio>
            </b2x.FormGroup>
          </b2x.Form>
        </Container>
      </TemplateSection>

      <TemplateSection title="Modals">
        <Container>
          <b2x.template.Modals />
        </Container>
      </TemplateSection>

      <TemplateSection title="Offcanvas">
        <Container>
          <b2x.template.Offcanvasses />
        </Container>
      </TemplateSection>

      {/* <TemplateSection title="Table">
        <div className="container-xxl">
          <AccountOrderTable />
          <AccountPaymentTable />
          <AccountCouponTable />
        </div>
      </TemplateSection> */}

      <TemplateSection title="Pagination">
        <Container>
          <b2x.template.Paginations />
        </Container>
      </TemplateSection>

      <TemplateSection title="Product Tiles">
        <div className="container-fluid">
          {page?.products && (
            <b2x.Listing name="Template page - Product Tiles" products={page.products}>
              <b2x.ProductsTiles
                enableExtraInfo
                products={page.products.map((product) => ({ product: product }))}
                productsPerRow={{ lg: 4, md: 2, sm: 2, xl: 4, xs: 2 }}
              />
            </b2x.Listing>
          )}
        </div>
      </TemplateSection>

      <TemplateSection title="Sliders">
        <Container>
          <h5>Slider A</h5>
        </Container>
        <SliderA alignItems="center" container="xxl" justifyContent="center" {...page?.content?.body.sliderA} />
        <Section background="lighter" className="mb-3" container="fluid" withDots>
          <Container>
            <h5>Slider B</h5>
          </Container>
          <SliderB {...page?.content?.body.sliderB} />
        </Section>
        {page?.products && (
          <Section className="my-5 py-3" container="fluid">
            <Container>
              <h5>Slider C</h5>
            </Container>
            <b2x.Listing name="Template page - Slider C" products={page.products}>
              <SliderC {...page.content?.body.sliderC} products={page.products} />
            </b2x.Listing>
          </Section>
        )}
        <Section background="lighter" className="my-5 py-5" container="fluid">
          <Container>
            <h5>Slider D</h5>
          </Container>
          <SliderD {...page?.content?.body.sliderD} />
        </Section>
        {/* <div className="container-fluid my-5 ">
          <div className="row">
            <div className="col-12">
              <h3 className="text-center text-uppercase h1 mb-0">
                <Icon name="instagram" size={48} /> Seguici
              </h3>
              <h3 className="text-center h1 mb-5 fw-bold text-primary">@equestro</h3>
              <InstagramSlider />
            </div>
          </div>
        </div> */}
      </TemplateSection>

      <TemplateSection title="Section Quote">
        <SectionA {...page?.content?.body.sectionA} />
      </TemplateSection>

      <TemplateSection title="Ordinamento prodotti">
        <Container>
          <Dropdown label="Ordina per">
            {sortingOptions.map((sortingOption) => (
              <b2x.DropdownItem active={sortingOption.active} key={sortingOption.label} onClick={sortingOption.onClick}>
                {sortingOption.label}
              </b2x.DropdownItem>
            ))}
          </Dropdown>
        </Container>
      </TemplateSection>

      <TemplateSection title="Countdown">
        <b2x.template.Countdown />
      </TemplateSection>

      {/* <div className="bg-lighter py-3">
        <TemplateSection title="Box Customer Homepage">
          <PageWithSubpageHeader
            subtitle="Ciao XXX scopri la tua area personale"
            title="Il mio account"
            url="www.google.it"
          />

          <PageWithSubpageHeader
            pageTitle="I miei ordini"
            subtitle="Ciao XXX scopri la tua area personale"
            title="Il mio account"
            url="www.google.it"
          />

          <Container>
            <div className="row justify-content-center">
              <div className="col-xl-3 col-md-4 mb-2 mb-md-4">
                <BoxHomeAccount
                  icon="profile"
                  link="www.google.it"
                  square
                  subtitle="Modifica i tuoi dati di registrazione"
                  title="I miei dati"
                />
              </div>
              <div className="col-xl-3 col-md-4 mb-2 mb-md-4">
                <BoxHomeAccount
                  icon="wishlist"
                  link="www.google.it"
                  square
                  subtitle="I tuoi prodotti preferiti a portata di click"
                  title="Wishlist"
                />
              </div>
              <div className="col-xl-3 col-md-4 mb-2 mb-md-4">
                <BoxHomeAccount
                  icon="windprof"
                  link="www.google.it"
                  square
                  subtitle="Controlla lo stato dei tuoi buoni in scadenza"
                  title="Coupon"
                />
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-xl-3 col-md-4 mb-2 mb-md-4">
                <BoxHomeAccount icon="profile" link="www.google.it" title="Carte di credito" />
              </div>
              <div className="col-xl-3 col-md-4 mb-2 mb-md-4">
                <BoxHomeAccount icon="wishlist" link="www.google.it" title="Invita un amico" />
              </div>
              <div className="col-xl-3 col-md-4 mb-2 mb-md-4">
                <BoxHomeAccount icon="windprof" link="www.google.it" title="Privacy" />
              </div>
            </div>
          </Container>
        </TemplateSection>
      </div> */}
    </Page>
  );
};

interface TemplateSectionProps {
  children?: React.ReactNode;
  title?: string;
}

const TemplateSection = ({ children, title }: TemplateSectionProps) => {
  return (
    <section className="my-5">
      {title && (
        <Container>
          <div className="row">
            <div className="col-12">
              <h1 className="mb-4">{title}</h1>
            </div>
          </div>
        </Container>
      )}
      {children}
    </section>
  );
};
