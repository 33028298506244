import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { Page } from './Page';

export const TestPage = () => {
  const product = b2x.useProductById('360909', { populate: b2x.appConfig.api?.productPopulate });

  return (
    <Page>
      <Container>
        {/* <b2x.LoginForm2 />
        <hr /> */}
        {product && <b2x.AddToCartForm2 product={product} scope="product" />}
      </Container>
    </Page>
  );
};
