import './CollectionsPage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { Container } from '../Container';
import { CollectionsPageContentType } from '../contentTypes';
import { PageHeader } from '../PageHeader';
import { Section } from '../Section';
import { SliderF } from '../slider/SliderF';
import { Page } from './Page';

export interface CollectionsPageProps {}

export const CollectionsPage = (props: CollectionsPageProps) => {
  const page = b2x.usePage<CollectionsPageContentType>({
    populate: {
      children: true,
    },
  });

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="CollectionsPage">
        {page?.content?.body.pageTitle && <PageHeader breadcrumb small title={page.content.body.pageTitle} />}

        {page?.content?.body.collectionList?.map((collection, index) => {
          return (
            <Section className="collection" key={collection.contentSectionId}>
              {(collection.mainImage || collection.mainVideo || collection.mainVideoV2) && (
                <div className="sectionMain mb-5">
                  {b2x.isVideoFromContentValid(collection.mainVideoV2) ? (
                    <b2x.VideoFromContent {...collection.mainVideoV2} />
                  ) : collection.mainVideo?.src ? (
                    <div className="mainVideo">
                      <b2x.DeprecatedVideoFromContent autoplay controls src={collection.mainVideo.src} />
                    </div>
                  ) : (
                    <div className="mainImage fit-image">
                      <b2x.ImageFromContent {...collection.mainImage} fluid />
                    </div>
                  )}
                </div>
              )}
              {(collection.introImageList || collection.introTitle || collection.introDescription) && (
                <div className="sectionIntro mb-3 mb-md-5">
                  <Container>
                    <div className="row justify-content-center">
                      {collection.introImageList && (
                        <div
                          className={classnames(
                            collection.introColumnOrder === 'image/text' ? 'order-1' : 'order-2',
                            'image-column fit-image text-center col-10 col-md-4 mb-5 mb-md-0'
                          )}
                        >
                          <SliderF sliderList={collection.introImageList} />
                        </div>
                      )}
                      {(collection.introTitle || collection.introDescription) && (
                        <div
                          className={classnames(
                            collection.introColumnOrder === 'text/image' ? 'order-1' : 'order-2',
                            'col-10 col-md-6 d-flex align-items-center'
                          )}
                        >
                          <div className="px-0 px-md-5">
                            {collection.introTitle && (
                              <h3 className="display-1 fw-bold text-uppercase lh-1 mb-4">
                                {b2x.formatHtml(collection.introTitle)}
                              </h3>
                            )}
                            {collection.introDescription && (
                              <div className="h3 fw-light mb-4">{b2x.formatHtml(collection.introDescription)}</div>
                            )}
                            {collection.introCta && (
                              <b2x.DeprecatedCta className="btn btn-primary text-uppercase" cta={collection.introCta}>
                                {collection.introCta.label}
                              </b2x.DeprecatedCta>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </Container>
                </div>
              )}
              {collection.bigTextImage && (
                <div className="sectionBigText mb-3 mb-md-5">
                  <Container>
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-10">
                        <div className="text-center fit-image">
                          <b2x.ImageFromContent {...collection.bigTextImage} fluid />
                        </div>
                      </div>
                    </div>
                  </Container>
                </div>
              )}
              {(collection.deepingImage || collection.deepingTitle || collection.deepingDescription) && (
                <div className="sectionDeeping text-center mb-5">
                  <Container>
                    <div className="row justify-content-center">
                      <div className="col-12 col-md-10">
                        {collection.deepingImage && (
                          <div className="deepingImg mb-5 fit-image">
                            <b2x.ImageFromContent {...collection.deepingImage} fluid />
                          </div>
                        )}
                        {collection.deepingTitle && (
                          <div className="deepingTitle mb-4">
                            <h3 className="display-1 fw-bold text-uppercase lh-1 mb-0">
                              {b2x.formatHtml(collection.deepingTitle)}
                            </h3>
                          </div>
                        )}
                        {collection.deepingDescription && (
                          <div className="deepingDescript h3 fw-light mb-5">
                            {b2x.formatHtml(collection.deepingDescription)}
                          </div>
                        )}
                        {collection.deepingCta?.label && (
                          <div className="deepingCta">
                            <b2x.DeprecatedCta className="btn btn-primary" cta={collection.deepingCta}>
                              {collection.deepingCta.label}
                            </b2x.DeprecatedCta>
                          </div>
                        )}
                      </div>
                    </div>
                  </Container>
                </div>
              )}
            </Section>
          );
        })}
      </div>
    </Page>
  );
};
