import classnames from 'classnames';
import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';

import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { useFormGroupContext } from '../FormGroupContext';

export interface TextAreaProps<FormValues extends FieldValues>
  extends Omit<
    React.DetailedHTMLProps<React.TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    keyof RegisterOptions
  > {
  name: Path<FormValues>;
  registerOptions?: RegisterOptions<FormValues>;
  size?: 'small' | 'large';
}
export const TextArea = <FormValues extends FieldValues>({
  className,
  name,
  placeholder,
  registerOptions,
  size,
  ...otherProps
}: TextAreaProps<FormValues>) => {
  const { formState, getFieldState, register } = useFormContext<FormValues>();
  const { id, required, withLabel } = useFormGroupContext();
  const fieldState = getFieldState(name, formState);

  // React.useEffect(() => {
  //   console.log(name, fieldState, {
  //     isDirty: formState.isDirty,
  //     isSubmitSuccessful: formState.isSubmitSuccessful,
  //     isSubmitted: formState.isSubmitted,
  //     isValid: formState.isValid,
  //     submitCount: formState.submitCount,
  //   });
  // }, [fieldState, formState, name]);

  // const valid = fieldState.isDirty && !fieldState.invalid;
  const invalid = fieldState.invalid;

  return renderUI({
    bs5: (
      <>
        <textarea
          {...register(name, {
            ...registerOptions,
            disabled: registerOptions?.disabled || undefined, // https://github.com/orgs/react-hook-form/discussions/10964
          })}
          {...otherProps}
          className={classnames(
            'form-control',
            { 'form-control-sm': size === 'small' },
            { 'form-control-lg': size === 'large' },
            // { 'is-valid': b2xHelpers.isValid },
            { 'is-invalid': invalid },
            className
          )}
          id={id}
          placeholder={!withLabel && required ? `${placeholder ?? ''} *` : placeholder}
        />
        <Feedback name={name} />
      </>
    ),
  });
};
