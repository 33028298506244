import { FileApiDto } from '@b2x/storefront-api-js-client/src/dto';
import classnames from 'classnames';
import React from 'react';
import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';

import { Button } from '../../Button';
import { appConfig } from '../../config';
import { t } from '../../i18n/i18n';
import { Icon } from '../../Icon';
import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { useFormGroupContext } from '../FormGroupContext';
import { InputGroup } from '../InputGroup';
import { InputGroupText } from '../InputGroupText';

interface InputProps<FormValues extends FieldValues>
  extends Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    'id' | 'size' | keyof RegisterOptions
  > {
  name: Path<FormValues>;
  registerOptions?: RegisterOptions<FormValues>;
  size?: 'small' | 'large';
}

const Input = <FormValues extends FieldValues>({
  autoComplete,
  className,
  name,
  placeholder,
  registerOptions,
  size,
  type,
  ...otherProps
}: InputProps<FormValues>) => {
  const { formState, getFieldState, register } = useFormContext<FormValues>();
  const { id, required, withLabel } = useFormGroupContext();
  const fieldState = getFieldState(name, formState);

  // React.useEffect(() => {
  //   console.log(name, fieldState, {
  //     isDirty: formState.isDirty,
  //     isSubmitSuccessful: formState.isSubmitSuccessful,
  //     isSubmitted: formState.isSubmitted,
  //     isValid: formState.isValid,
  //     submitCount: formState.submitCount,
  //   });
  // }, [fieldState, formState, name]);

  // const valid = fieldState.isDirty && !fieldState.invalid;
  const invalid = fieldState.invalid;

  return renderUI({
    bs5: (
      <>
        <input
          {...register(name, {
            ...registerOptions,
            disabled: registerOptions?.disabled || undefined, // https://github.com/orgs/react-hook-form/discussions/10964
          })}
          {...otherProps}
          autoComplete={type === 'email' ? 'username' : autoComplete}
          className={classnames(
            'form-control',
            { 'form-control-sm': size === 'small' },
            { 'form-control-lg': size === 'large' },
            // { 'is-valid': valid },
            { 'is-invalid': invalid },
            className
          )}
          id={id}
          placeholder={!withLabel && required ? `${placeholder ?? ''} *` : placeholder}
          type={type}
        />
        <Feedback name={name} />
      </>
    ),
  });
};

export interface TextInputProps<FormValues extends FieldValues> extends InputProps<FormValues> {
  type?: 'text' | 'email' | 'tel';
}
export const TextInput = <FormValues extends FieldValues>({
  type = 'text',
  ...otherProps
}: TextInputProps<FormValues>): React.ReactElement => {
  return <Input<FormValues> {...otherProps} type={type} />;
};

export interface NumberInputProps<FormValues extends FieldValues> extends InputProps<FormValues> {}
export const NumberInput = <FormValues extends FieldValues>(
  props: NumberInputProps<FormValues>
): React.ReactElement => <Input<FormValues> {...props} type="number" />;

export interface DateInputProps<FormValues extends FieldValues> extends InputProps<FormValues> {}
export const DateInput = <FormValues extends FieldValues>({
  // name,
  // onBlur,
  // onFocus,
  // placeholder,
  ...otherProps
}: DateInputProps<FormValues>): React.ReactElement => {
  // const [type, setType] = React.useState<'date' | 'text'>(placeholder ? 'text' : 'date');
  // const { values } = useFormikContext();
  // const ref = React.useRef<HTMLInputElement>(null);

  // const handleFocus = React.useCallback(
  //   (event: React.FocusEvent<HTMLInputElement, Element>) => {
  //     setType('date');
  //     onFocus && onFocus(event);
  //     wait(100).then(() => {
  //       ref.current?.click();
  //     });
  //   },
  //   [onFocus]
  // );

  // const handleBlur = React.useCallback(
  //   (event: React.FocusEvent<HTMLInputElement, Element>) => {
  //     const value = getIn(values, name);
  //     if (placeholder !== undefined && (value === undefined || value === '')) {
  //       setType('text');
  //     }
  //     onBlur && onBlur(event);
  //   },
  //   [name, onBlur, placeholder, values]
  // );

  return (
    <Input<FormValues>
      {...otherProps}
      // innerRef={ref}
      // name={name}
      // onBlur={handleBlur}
      // onFocus={handleFocus}
      // placeholder={placeholder}
      type="date"
    />
  );
};

export interface TimeInputProps<FormValues extends FieldValues> extends InputProps<FormValues> {}
export const TimeInput = <FormValues extends FieldValues>(props: TimeInputProps<FormValues>): React.ReactElement => (
  <Input<FormValues> {...props} type="time" />
);

export interface DateTimeInputProps<FormValues extends FieldValues> extends InputProps<FormValues> {}
export const DateTimeInput = <FormValues extends FieldValues>(
  props: DateTimeInputProps<FormValues>
): React.ReactElement => <Input<FormValues> {...props} type="datetime-local" />;

export interface FileInputProps<FormValues extends FieldValues> extends Omit<InputProps<FormValues>, 'readOnly'> {
  accept?: string;
  // buttonVariant?: string; // da gestire
  capture?: 'user' | 'environment';
  // maxSize?: number; // default 10000 // da gestire
  // multiple?: boolean; // da gestire
  onUpload?(file: FileApiDto): void;
  placeholder?: string;
}

export const FileInput = <FormValues extends FieldValues>(props: FileInputProps<FormValues>): React.ReactElement => (
  <Input<FormValues> {...props} type="file" />
);

export interface FileUploaderInputProps<FormValues extends FieldValues> extends FileInputProps<FormValues> {
  folder?: string;
}

// export const FileUploaderInput = <FormValues extends FieldValues>(
//   props: FileUploaderInputProps<FormValues>
// ): React.ReactElement => {
//   const [uploading, setUploading] = React.useState<boolean>(false);
//   const { id } = useFormGroupContext();
//   const { setFieldValue } = useFormikContext();
//   const [file, setFile] = React.useState<FileApiDto>();

//   const {
//     className,
//     disabled,
//     folder,
//     innerRef,
//     name,
//     onChange,
//     onFormikChange,
//     onUpload,
//     placeholder,
//     size,
//     ...otherProps
//   } = props;
//   const { b2xHelpers } = useField(props, { ...otherProps, name: name });

//   const { upload } = useFileApi();

//   const handleChange = React.useCallback(
//     (event: React.ChangeEvent<HTMLInputElement>) => {
//       onChange && onChange(event);
//       if (event.target.files && event.target.files.length > 0) {
//         setFile(undefined);
//         setFieldValue(name, '');
//         setUploading(true);
//         upload(event.target.files[0], { folder: folder })
//           .then((response) => {
//             onUpload && onUpload(response.data);
//             setFile(response.data);
//             setFieldValue(name, response.data.id);
//           })
//           .finally(() => {
//             setUploading(false);
//           });
//       }
//     },
//     [folder, name, onChange, onUpload, setFieldValue, upload]
//   );

//   return renderUI({
//     bs5: (
//       <label
//         className={classnames(className, 'd-block')}
//         htmlFor={id}
//         style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
//       >
//         <span
//           className={classnames(
//             'btn btn-light',
//             { 'btn-sm': size === 'small' },
//             { 'btn-lg': size === 'large' },
//             { 'border-danger': b2xHelpers.isInvalid },
//             { disabled: disabled }
//           )}
//         >
//           {t('misc.fileInput.label')}
//         </span>
//         <span className="ps-2">
//           {uploading
//             ? t('misc.fileInput.uploading')
//             : file
//             ? file.title
//             : placeholder || t('misc.fileInput.placeholder')}
//         </span>
//         <input
//           {...otherProps}
//           className={classnames({ 'is-valid': b2xHelpers.isValid }, { 'is-invalid': b2xHelpers.isInvalid }, className)}
//           disabled={disabled || uploading}
//           id={id}
//           onChange={handleChange}
//           ref={innerRef}
//           style={{ display: 'none' }}
//           type="file"
//         />
//         <Feedback name={name} />
//       </label>
//     ),
//   });
// };

// export interface ImageUploaderInputProps<FormValues extends FieldValues> extends FileInputProps<FormValues> {
//   album?: string;
// }

// export const ImageUploaderInput = (props: ImageUploaderInputProps): React.ReactElement => {
//   const [uploading, setUploading] = React.useState<boolean>(false);
//   const { id } = useFormGroupContext();
//   const { setFieldValue } = useFormikContext();
//   const [image, setImage] = React.useState<ImageApiDto>();

//   const {
//     album,
//     className,
//     disabled,
//     innerRef,
//     name,
//     onChange,
//     onFormikChange,
//     onUpload,
//     placeholder,
//     size,
//     ...otherProps
//   } = props;
//   const { b2xHelpers } = useField(props, { ...otherProps, name: name });

//   const { imageUpload } = useFileApi();

//   const handleChange = React.useCallback(
//     (event: React.ChangeEvent<HTMLInputElement>) => {
//       onChange && onChange(event);
//       if (event.target.files && event.target.files.length > 0) {
//         setImage(undefined);
//         setFieldValue(name, '');
//         setUploading(true);
//         imageUpload(event.target.files[0], { album: album })
//           .then((response) => {
//             onUpload && onUpload(response.data);
//             setImage(response.data);
//             setFieldValue(name, response.data.id);
//           })
//           .finally(() => {
//             setUploading(false);
//           });
//       }
//     },
//     [album, name, onChange, onUpload, setFieldValue, imageUpload]
//   );

//   return renderUI({
//     bs5: (
//       <label
//         className={classnames(className, 'd-block')}
//         htmlFor={id}
//         style={{ cursor: disabled ? 'not-allowed' : 'pointer' }}
//       >
//         <span
//           className={classnames(
//             'btn btn-light',
//             { 'btn-sm': size === 'small' },
//             { 'btn-lg': size === 'large' },
//             { 'border-danger': b2xHelpers.isInvalid },
//             { disabled: disabled }
//           )}
//         >
//           {t('misc.fileInput.label')}
//         </span>
//         <span className="ps-2">
//           {uploading
//             ? t('misc.fileInput.uploading')
//             : image
//             ? image.title
//             : placeholder || t('misc.fileInput.placeholder')}
//         </span>
//         <input
//           {...otherProps}
//           className={classnames({ 'is-valid': b2xHelpers.isValid }, { 'is-invalid': b2xHelpers.isInvalid }, className)}
//           disabled={disabled || uploading}
//           id={id}
//           onChange={handleChange}
//           ref={innerRef}
//           style={{ display: 'none' }}
//           type="file"
//         />
//         <Feedback name={name} />
//       </label>
//     ),
//   });
// };

export interface PasswordInputProps<FormValues extends FieldValues> extends InputProps<FormValues> {
  autoComplete: 'new-password' | 'current-password';
  placeholder?: string;
}
export const PasswordInput = <FormValues extends FieldValues>({
  name,
  ...otherProps
}: PasswordInputProps<FormValues>) => {
  const [visible, setVisible] = React.useState<boolean>(false);

  const handleButtonClick = React.useCallback(() => {
    setVisible((prevState) => !prevState);
  }, []);

  return (
    <InputGroup names={[name]}>
      <Input<FormValues> name={name} {...otherProps} style={{ borderRight: 0 }} type={visible ? 'text' : 'password'} />
      <InputGroupText style={{ background: 'none' }}>
        <Button onClick={handleButtonClick} variant="blank">
          {appConfig.icons?.passwordToggle ? (
            <Icon name={visible ? appConfig.icons.passwordToggle.visible : appConfig.icons.passwordToggle.hidden} />
          ) : (
            <strong className="extra-small">
              <u>{visible ? t('misc.passwordInput.hide') : t('misc.passwordInput.show')}</u>
            </strong>
          )}
        </Button>
      </InputGroupText>
    </InputGroup>
  );
};
