import { b2x } from '@b2x/react/src';

import { PageHeader } from '../PageHeader';
import { SearchSection } from '../SearchSection';
import { ErrorSearchPage } from './ErrorSearchPage';
import { Page } from './Page';

export interface SearchPageProps {}

export const SearchPage = (props: SearchPageProps) => {
  const { fetching, page, searchResult } = b2x.useSearch({
    defaultPageSize: 16,
    pageOptions: {
      populate: {
        breadcrumb: true,
      },
    },
    populate: {
      filters: { selector: true },
      items: {
        // attributes: true,
        priceRange: true,
        // skus: {
        //   //     attributes: true,
        //   price: true,
        // },
        // tags: true,
        // variantProducts: true,
      },
    },
  });

  return (
    <>
      {searchResult?.itemsCount === 0 ? (
        <ErrorSearchPage />
      ) : (
        <Page noPaddingTop thingsToLoadBeforePageReady={[searchResult]}>
          <PageHeader breadcrumb subtitle={page?.description} title={page ? page.name : 'Search'} />
          <SearchSection fetching={fetching} searchFormProps={{ searchResult: searchResult }} />
        </Page>
      )}
    </>
  );
};
