import './Dropdown.scss';

import { b2x } from '@b2x/react/src';

import { IconName, IconSize } from './Icon';

export type ButtonVariant = 'outline-link';

export const Dropdown = ({
  children,
  ...props
}: b2x.DropdownProps<ButtonVariant, IconName, IconSize>): React.ReactElement => {
  return (
    <b2x.Dropdown
      {...props}
      buttonClassname="fw-light btn-sm"
      iconEnd={{ className: 'ms-5', name: 'small-arrow-down', size: 12 }}
    >
      {children}
    </b2x.Dropdown>
  );
};
