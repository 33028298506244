import './LookPage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { LookPageContentType } from '../contentTypes';
import { PageHeader } from '../PageHeader';
import { SliderG } from '../slider/SliderG';
import { Page } from './Page';

export interface LookPageProps {}

export const LookPage = (props: LookPageProps) => {
  const page = b2x.usePage<LookPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <div className="LookPage">
        {page?.content?.body.pageTitle && <PageHeader breadcrumb small title={page.content.body.pageTitle} />}
        <Container>
          <b2x.Row className="justify-content-center">
            {page?.content?.body.lookImage && (
              <b2x.Col className="fit-image col-10 col-md-7 col-lg-4 mb-5">
                <b2x.ImageFromContent {...page.content.body.lookImage} fluid />
              </b2x.Col>
            )}
            {page?.content?.body.lookTitle && (
              <b2x.Col className="col-10 text-center mb-3">
                <h3 className="fw-light">
                  {page.content.body.lookTitle.split(' ').map((string, index) => (
                    <>{index > 0 ? <span className="h1 fw-light"> /{string}</span> : string}</>
                  ))}
                </h3>
              </b2x.Col>
            )}
          </b2x.Row>
        </Container>
        <b2x.Listing name="Look page - Bottom slider" products={page?.products}>
          <SliderG products={page?.products} />
        </b2x.Listing>
      </div>
    </Page>
  );
};
