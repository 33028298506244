import { b2x } from '@b2x/react/src';

import { CustomConsentsContentType } from '../contentTypes';
import { t } from '../i18n/i18n';

type AdditionalPropertiesFormValues = {
  CONTEST_202305_FIL1: b2x.formikString;
  CONTEST_202305_FIL2: b2x.formikString;
  CONTEST_202305_INSTAGRAM_PROFILE: b2x.formikString;
  CONTEST_202305_REG: b2x.formikDateTime;
  CONTEST_202305_TIKTOK_PROFILE: b2x.formikString;
};

type AdditionalPropertiesValidationSchema = b2x.yup.ObjectSchema<{
  CONTEST_202305_FIL1: b2x.yup.StringSchema;
  CONTEST_202305_FIL2: b2x.yup.StringSchema;
  CONTEST_202305_INSTAGRAM_PROFILE: b2x.yup.StringSchema;
  CONTEST_202305_REG: b2x.yup.DateSchema;
  CONTEST_202305_TIKTOK_PROFILE: b2x.yup.StringSchema;
}>;

type CustomPropertiesFormValues = {
  adult: b2x.formikString;
  regulation: b2x.formikBoolean;
};

type CustomPropertiesValidationSchema = b2x.yup.ObjectSchema<{
  adult: b2x.yup.StringSchema;
  regulation: b2x.yup.BooleanSchema;
}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesChildrenFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type AdditionalPropertiesChildrenValidationSchema = b2x.yup.ObjectSchema<{}>;

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesChildrenFormValues = {};

// eslint-disable-next-line @typescript-eslint/ban-types
type CustomPropertiesChildrenValidationSchema = b2x.yup.ObjectSchema<{}>;

export interface ContestLoggedFormProps {
  onSuccess?(): void;
  title?: string;
}

export const ContestLoggedForm = ({ onSuccess, title }: ContestLoggedFormProps) => {
  const customer = b2x.useCustomer({
    populate: {
      additionalPropertiesExt: true,
    },
  });
  const customConsentsContent = b2x.useContent<CustomConsentsContentType>('CUSTOM_CONSENTS_CONTENT');

  return (
    <>
      {customer && !customer.additionalPropertiesExt?.CONTEST_202305_REG ? (
        <>
          {title && <h3 className="h1 text-uppercase fw-bold text-center mb-0">{title}</h3>}
          <h4 className="mb-5 fw-semi-bold text-center">
            {t('contest.registration.customerTitle', {
              name: customer.name,
              surname: customer.surname,
            })}
          </h4>
          <b2x.CustomerFormHelper<
            AdditionalPropertiesFormValues,
            AdditionalPropertiesValidationSchema,
            CustomPropertiesFormValues,
            CustomPropertiesValidationSchema,
            AdditionalPropertiesChildrenFormValues,
            AdditionalPropertiesChildrenValidationSchema,
            CustomPropertiesChildrenFormValues,
            CustomPropertiesChildrenValidationSchema
          >
            additionalProperties={{
              formValues: {
                CONTEST_202305_FIL1: (values) => values.additionalProperties?.CONTEST_202305_FIL1 ?? '',
                CONTEST_202305_FIL2: (values) => values.additionalProperties?.CONTEST_202305_FIL2 ?? '',
                CONTEST_202305_INSTAGRAM_PROFILE: (values) =>
                  values.additionalProperties?.CONTEST_202305_INSTAGRAM_PROFILE ?? '',
                CONTEST_202305_REG: (values) => values.additionalProperties?.CONTEST_202305_REG ?? '',
                CONTEST_202305_TIKTOK_PROFILE: (values) =>
                  values.additionalProperties?.CONTEST_202305_TIKTOK_PROFILE ?? '',
              },
              initialValues: {
                CONTEST_202305_FIL1: b2x.getInitialString(),
                CONTEST_202305_FIL2: b2x.getInitialString(),
                CONTEST_202305_INSTAGRAM_PROFILE: b2x.getInitialString(),
                CONTEST_202305_REG: b2x.getInitialDateTime(new Date()),
                CONTEST_202305_TIKTOK_PROFILE: b2x.getInitialString(),
              },
              validationSchema: b2x.yup
                .object({
                  CONTEST_202305_FIL1: b2x.yup.string(),
                  CONTEST_202305_FIL2: b2x.yup.string().required(),
                  CONTEST_202305_INSTAGRAM_PROFILE: b2x.yup.string().required(),
                  CONTEST_202305_REG: b2x.yup.date().required(),
                  CONTEST_202305_TIKTOK_PROFILE: b2x.yup.string(),
                })
                .when('customProperties.adult', {
                  is: 'children',
                  then: (schema) =>
                    schema.shape({
                      CONTEST_202305_FIL1: b2x.yup.string().required(),
                    }),
                }),
            }}
            customProperties={{
              initialValues: {
                adult: b2x.getInitialString(),
                regulation: b2x.getInitialBoolean(),
              },
              validationSchema: b2x.yup.object({
                adult: b2x.yup.string().required(),
                regulation: b2x.yup.boolean().required().oneOf([true]),
              }),
            }}
            customer={customer}
            onSuccess={onSuccess}
            validationSchemaSelector={{
              birthdate: false,
              children: { birthdate: false, name: false, sex: false, surname: false },
              name: false,
              profile: false,
              sex: false,
              surname: false,
            }}
          >
            {({ fieldsHelper, formik }) => (
              <>
                <b2x.Row>
                  <b2x.Col>
                    <b2x.FormGroup
                      label={t('form.contestForm.instagram.label')}
                      names={['additionalProperties.CONTEST_202305_INSTAGRAM_PROFILE']}
                    >
                      <b2x.TextInput
                        name="additionalProperties.CONTEST_202305_INSTAGRAM_PROFILE"
                        placeholder={t('form.contestForm.instagram.placeholder')}
                      />
                    </b2x.FormGroup>
                  </b2x.Col>
                  <b2x.Col>
                    <b2x.FormGroup
                      label={t('form.contestForm.tiktok.label')}
                      names={['additionalProperties.CONTEST_202305_TIKTOK_PROFILE']}
                    >
                      <b2x.TextInput
                        name="additionalProperties.CONTEST_202305_TIKTOK_PROFILE"
                        placeholder={t('form.contestForm.tiktok.placeholder')}
                      />
                    </b2x.FormGroup>
                  </b2x.Col>
                </b2x.Row>
                <b2x.FormGroup
                  label={t('form.contestForm.file.label')}
                  names={['additionalProperties.CONTEST_202305_FIL2']}
                  omitForAttribute
                >
                  <b2x.FileUploaderInput
                    accept="video/*"
                    folder="CONTEST_202305"
                    name="additionalProperties.CONTEST_202305_FIL2"
                  />
                </b2x.FormGroup>
                {/* <b2x.FormGroup
                  label={t('form.contestForm.photo.label')}
                  names={['additionalProperties.CONTEST_202305_IMG1', 'additionalProperties.CONTEST_202305_IMG2']}
                  omitForAttribute
                >
                  <b2x.ImageUploaderInput
                    album="CONTEST_202305"
                    className="mb-3"
                    name="additionalProperties.CONTEST_202305_IMG1"
                    placeholder={t('form.contestForm.photo.placeholder.rideIt')}
                  />
                  <b2x.ImageUploaderInput
                    album="CONTEST_202305"
                    name="additionalProperties.CONTEST_202305_IMG2"
                    placeholder={t('form.contestForm.photo.placeholder.liveIt')}
                  />
                </b2x.FormGroup> */}
                <b2x.FormGroup label={undefined} names={['customProperties.adult']} omitForAttribute>
                  <b2x.Radios
                    radios={[
                      {
                        id: 'man',
                        inline: true,
                        label: t('form.contestForm.adult.adult'),
                        name: 'customProperties.adult',
                        value: 'man',
                      },
                      {
                        id: 'children',
                        inline: true,
                        label: t('form.contestForm.adult.minor'),
                        name: 'customProperties.adult',
                        value: 'children',
                      },
                    ]}
                  />
                </b2x.FormGroup>
                {formik.values.customProperties?.adult === 'children' && (
                  <>
                    <p>{b2x.formatHtml(customConsentsContent?.body.contestDisclaimerText)}</p>
                    <b2x.FormGroup
                      label={t('form.contestForm.disclaimer.label')}
                      names={['additionalProperties.CONTEST_202305_FIL1']}
                      omitForAttribute
                    >
                      <b2x.FileUploaderInput folder="CONTEST_202305" name="additionalProperties.CONTEST_202305_FIL1" />
                    </b2x.FormGroup>
                  </>
                )}
                <b2x.FormGroup label={undefined} names={['customProperties.regulation']}>
                  <b2x.Checkbox
                    id={'customProperties.regulation'}
                    label={b2x.formatHtml(customConsentsContent?.body.contestFlagRegulationConsent)}
                    labelClassName="fw-light extra-small text-dark"
                    name={'customProperties.regulation'}
                  />
                </b2x.FormGroup>
                <div className="text-center">
                  <b2x.Button {...fieldsHelper.buttons.submit} />
                </div>
              </>
            )}
          </b2x.CustomerFormHelper>
        </>
      ) : (
        <div className="text-center">
          <h3 className="h1 text-uppercase fw-bold mb-4">{t('contest.registration.messages.alredyRegistered')}</h3>
          <h4 className="mb-0 fw-light">{t('contest.registration.messages.checkEmailNotification')}</h4>
        </div>
      )}
    </>
  );
};
