import classnames from 'classnames';
import { FieldValues, Path, RegisterOptions, useFormContext } from 'react-hook-form';

import { renderUI } from '../../util';
import { Feedback } from '../Feedback';
import { useFormGroupContext } from '../FormGroupContext';
import { Option, OptionProps } from './Option';

export interface SelectProps<FormValues extends FieldValues>
  extends Omit<
    React.DetailedHTMLProps<React.SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>,
    'size' | keyof RegisterOptions
  > {
  includeEmptyOption?: boolean;
  name: Path<FormValues>;
  options?: Array<OptionProps>;
  registerOptions?: RegisterOptions<FormValues>;
  size?: 'small' | 'large';
}

export const Select = <FormValues extends FieldValues>({
  children,
  className,
  defaultValue,
  includeEmptyOption,
  name,
  options,
  placeholder,
  registerOptions,
  size,
  ...otherProps
}: SelectProps<FormValues>) => {
  const { formState, getFieldState, register } = useFormContext<FormValues>();
  const { id, required, withLabel } = useFormGroupContext();
  const fieldState = getFieldState(name, formState);

  const invalid = fieldState.invalid;

  return renderUI({
    bs5: (
      <>
        <select
          {...register(name, {
            ...registerOptions,
            disabled: registerOptions?.disabled || undefined, // https://github.com/orgs/react-hook-form/discussions/10964
          })}
          {...otherProps}
          className={classnames(
            'form-select',
            { 'form-select-sm': size === 'small' },
            { 'form-select-lg': size === 'large' },
            // { 'is-valid': b2xHelpers.isValid },
            { 'is-invalid': invalid },
            className
          )}
          id={id}
        >
          {placeholder && <option value="">{!withLabel && required ? `${placeholder} *` : placeholder}</option>}
          {includeEmptyOption && <option value=""></option>}
          {options?.map((option) => (
            <Option key={option.value} {...option} />
          ))}
          {children}
        </select>
        <Feedback name={name} />
      </>
    ),
  });
};
