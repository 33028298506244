import { Button } from '../Button';
import { prettyStringify } from '../util';
import { TextInput } from './fields/Input';
import { Radio } from './fields/RadioCheck';
import { Select } from './fields/Select';
import { TextArea } from './fields/TextArea';
import { Form } from './Form';
import { FormGroup } from './FormGroup';
import { useAddToCartForm, UseAddToCartFormProps } from './useAddToCartForm';

export interface AddToCartForm2Props extends UseAddToCartFormProps {}

export const AddToCartForm2 = ({ ...useAddToCartFormProps }: AddToCartForm2Props) => {
  const { addToCartForm, fieldsHelper, formHelper, selectedProductVariant, selectedSku } =
    useAddToCartForm(useAddToCartFormProps);

  return (
    <Form {...formHelper}>
      <div>### selectedProductVariant: {selectedProductVariant.id}</div>
      <div>### selectedSku: {selectedSku?.id}</div>
      <Button
        label="set productVariantId"
        // eslint-disable-next-line react/jsx-no-bind
        onClick={() => {
          addToCartForm.setValue('productVariantId', '360957');
        }}
      />
      {fieldsHelper.productVariants.formFields.length > 1 && (
        <FormGroup {...fieldsHelper.productVariants.formGroup}>
          {fieldsHelper.productVariants.formFields.map((formField) => (
            <Radio key={formField.productVariant.id} {...formField.radio} />
          ))}
        </FormGroup>
      )}
      {/* {Object.entries(fieldsHelper.skuAttributes).map(([name, { formGroup, radios }]) => (
        <FormGroup key={name} {...formGroup}>
          {radios.map(({ radio }) => (
            <Radio key={radio.id} {...radio} />
          ))}
        </FormGroup>
      ))} */}
      <FormGroup {...fieldsHelper.skus.formGroup}>
        {fieldsHelper.skus.formFields.map((formField) => (
          <Radio key={formField.sku.id} {...formField.radio} />
        ))}
      </FormGroup>
      <FormGroup {...fieldsHelper.quantity.formGroup}>
        <Select {...fieldsHelper.quantity.select} />
      </FormGroup>
      {fieldsHelper.giftCard && (
        <>
          <FormGroup {...fieldsHelper.giftCard.senderName.formGroup}>
            <TextInput {...fieldsHelper.giftCard.senderName.textInput} />
          </FormGroup>
          <FormGroup {...fieldsHelper.giftCard.receiverName.formGroup}>
            <TextInput {...fieldsHelper.giftCard.receiverName.textInput} />
          </FormGroup>
          <FormGroup {...fieldsHelper.giftCard.receiverEmail.formGroup}>
            <TextInput {...fieldsHelper.giftCard.receiverEmail.textInput} />
          </FormGroup>
          <FormGroup {...fieldsHelper.giftCard.message.formGroup}>
            <TextArea {...fieldsHelper.giftCard.message.textArea} />
          </FormGroup>
        </>
      )}
      {/* <AddToCartFormButton fieldsHelper={fieldsHelper} selectedSku={selectedSku} /> */}
      <Button {...fieldsHelper.buttons.submit} />
      {prettyStringify()}
    </Form>
  );
};
