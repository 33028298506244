import './LandingTemplateAPage.scss';

import { b2x } from '@b2x/react/src';

import { Container } from '../Container';
import { LandingTemplateAPageContentType } from '../contentTypes';
import { Section } from '../Section';
import { SliderB } from '../slider/SliderB';
import { SliderD } from '../slider/SliderD';
import { Page } from './Page';

export const LandingTemplateAPage = () => {
  const page = b2x.usePage<LandingTemplateAPageContentType>({
    populate: {
      products: b2x.appConfig.api?.productTilePopulate,
    },
  });

  const body = page?.content?.body;
  const products = page?.products;

  return (
    <Page className="scuderia-page" noPaddingBottom noPaddingTop thingsToLoadBeforePageReady={[page]}>
      {body?.bannerList?.map(
        ({ backgroundColor, contentSectionId, img, slider, textColor, title, video, videoGallery }) => {
          let content: React.ReactNode = null;
          if (img?.src?.xs) {
            content = <b2x.ImageFromContent className="w-100" {...img} fluid />;
          } else if (video?.variants?.xs?.source?.vimeo?.id) {
            content = (
              <div className="mx-5">
                <b2x.VideoFromContent {...video} />
              </div>
            );
          } else if (slider?.imgList?.length) {
            content = (
              <Section container="fluid">
                {!!slider.title ? (
                  <SliderB
                    content={slider.description}
                    contentClassName={`text-${textColor}`}
                    sliderList={slider.imgList}
                    titleA={slider.title}
                    titleAClassName={`text-${textColor}`}
                  />
                ) : (
                  <SliderD sliderList={slider.imgList} />
                )}
              </Section>
            );
          } else if (videoGallery?.desktopVideoList?.length) {
            content = (
              <Section container="fluid" containerClassName="px-0">
                <div className="d-md-block d-none overflow-hidden">
                  <b2x.Row gap={5}>
                    {videoGallery.desktopVideoList.map((_video) => (
                      <b2x.Col key={_video.contentSectionId} size={4}>
                        <b2x.VideoFromContent {..._video.video} />
                      </b2x.Col>
                    ))}
                  </b2x.Row>
                </div>
                <div className="d-block d-md-none">
                  <b2x.VideoFromContent {...videoGallery.mobileVideo} />
                </div>
              </Section>
            );
          }

          return (
            <div className={`bg-${backgroundColor} d-flex flex-column`} key={contentSectionId}>
              {title && (
                <div className="position-relative">
                  <Container>
                    <b2x.Row>
                      <b2x.Col size={{ lg: 2, xs: 'auto' }} />
                      <b2x.Col size={{ lg: 8, xs: 12 }}>
                        <h4 className={`text-${textColor} my-5 py-5 lh-lg text-center`}>{b2x.formatHtml(title)}</h4>
                      </b2x.Col>
                      <b2x.Col size={{ lg: 2, xs: 'auto' }} />
                    </b2x.Row>
                  </Container>
                  <div className="widget-bars-container d-lg-flex d-none flex-column">
                    <div className="bar"></div>
                    <div className="bar"></div>
                  </div>
                </div>
              )}
              {content}
            </div>
          );
        }
      )}
      <div className="container-fluid pt-5 bg-black">
        {!!products?.length && (
          <b2x.Listing name="Template page - Products tiles on black background" products={products}>
            <b2x.ProductsTiles
              className="pt-5"
              enableExtraInfo
              populate={b2x.appConfig.api?.productTilePopulate}
              products={products.map((product) => ({ product: product }))}
              productsPerRow={{ lg: 4, md: 2, sm: 2, xl: 4, xs: 2 }}
            />
          </b2x.Listing>
        )}

        {body?.ctaProducts && body.ctaProducts.label && (
          <div className="text-center mt-5">
            <b2x.DeprecatedCta cta={body.ctaProducts}>
              <div className="btn btn-primary">{body.ctaProducts.label}</div>
            </b2x.DeprecatedCta>
          </div>
        )}
      </div>
      <b2x.ImageFromContent {...body?.img} className="w-100" fluid />
    </Page>
  );
};
