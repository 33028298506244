import './EventList.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { CrewEventPageContentType, CrewEventsPageContentType } from './contentTypes';

export interface EventListProps {}

export const EventList = (props: EventListProps) => {
  const page = b2x.usePageByCode<CrewEventsPageContentType>('SITE_CRW_EVENTS', {
    populate: {
      children: {
        content: true,
      },
      content: true,
    },
  });

  return (
    <div className="EventList">
      {page?.children?.length && page.children.length > 0 && (
        <b2x.Row>
          {page.children.map((event, index) => (
            <b2x.Col
              className={classnames('col-12 mb-3 mb-md-4', index < 2 ? 'col-md-6' : 'col-md-6 col-lg-3')}
              key={event.id}
            >
              {event.content &&
                b2x.typedContent<CrewEventPageContentType>(event.content, (content) => (
                  <div className="d-flex flex-wrap position-relative">
                    {content.body.abstract?.image && (
                      <div className={classnames('bg-lighter', index < 2 ? 'col-12 col-lg-6' : 'col-4 col-md-12')}>
                        <b2x.ImageFromContent {...content.body.abstract.image} fluid />
                      </div>
                    )}
                    <div
                      className={classnames(
                        'bg-lighter text-center p-4 text-container d-flex flex-wrap flex-column',
                        index < 2 ? 'col-12 col-lg-6' : 'col-8 col-md-12'
                      )}
                    >
                      <div className="flex-grow-1 d-flex align-items-center">
                        <div>
                          {content.body.title && <h3 className="h6 fw-bold text-uppercase">{content.body.title}</h3>}
                          {content.body.abstract?.description && index < 2 && (
                            <div className="mt-3 fw-light">{b2x.formatHtml(content.body.abstract.description)}</div>
                          )}
                        </div>
                      </div>
                      <div>
                        <b2x.DeprecatedCta
                          className="text-uppercase text-decoration-none fw-medium stretched-link"
                          cta={{ to: { href: event.fullPath } }}
                        >
                          Leggi di più
                        </b2x.DeprecatedCta>
                        {content.body.date && index < 2 && (
                          <h6 className="mt-1 text-capitalize text-dark fw-light small">
                            {b2x.formatDateFromContent(content.body.date, 'dddd L')}
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </b2x.Col>
          ))}
        </b2x.Row>
      )}
    </div>
  );
};
