import './AmbassadorList.scss';

import { b2x } from '@b2x/react/src';

import { CrewAmbassadorPageContentType, CrewAmbassadorsPageContentType } from './contentTypes';

export interface AmbassadorListProps {
  exclude?: string;
}

export const AmbassadorList = ({ exclude }: AmbassadorListProps) => {
  const page = b2x.usePageByCode<CrewAmbassadorsPageContentType>('SITE_CRW_AMBASSADORS', {
    populate: {
      children: {
        content: true,
      },
      content: true,
    },
  });

  return (
    <div className="AmbassadorList">
      {page?.children?.length && page.children.length > 0 && (
        <b2x.Row cols={{ lg: 4, md: 2, sm: 2, xl: 4, xs: 1, xxl: 4 }}>
          {page.children.map(
            (ambassador) =>
              exclude !== ambassador.id && (
                <b2x.Col className="mb-3" key={ambassador.id}>
                  {ambassador.content &&
                    b2x.typedContent<CrewAmbassadorPageContentType>(ambassador.content, (content) => (
                      <b2x.router.Link className="text-white" to={ambassador.fullPath}>
                        {content.body.previewImage && (
                          <b2x.ImageFromContent {...content.body.previewImage} className="mb-3" fluid />
                        )}
                        {content.body.name && (
                          <div className="fw-semi-bold d-inline-block lh-1 bg-black p-2 py-lg-3 pe-lg-5 ps-lg-3">
                            {content.body.name}
                          </div>
                        )}
                      </b2x.router.Link>
                    ))}
                </b2x.Col>
              )
          )}
        </b2x.Row>
      )}
    </div>
  );
};
