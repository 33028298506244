// eslint-disable-next-line no-restricted-imports
import { FieldValues, useForm as _useForm, UseFormProps, UseFormRegister, UseFormReturn } from 'react-hook-form';

import { t } from '../i18n/i18n';

export const useForm = <FormValues extends FieldValues = FieldValues, TContext extends object = object>(
  props?: UseFormProps<FormValues, TContext>
): UseFormReturn<FormValues, TContext> => {
  const { register: register_, ...rest } = _useForm({ ...props, mode: props?.mode ?? 'onBlur' });

  const register: UseFormRegister<FormValues> = (name, options) =>
    register_(name, {
      ...options,
      max:
        typeof options?.max === 'number'
          ? { message: t('yup.number.max', { max: options.max }), value: options.max }
          : options?.min,
      maxLength:
        typeof options?.maxLength === 'number'
          ? { message: t('yup.string.max', { max: options.maxLength }), value: options.maxLength }
          : options?.maxLength,
      min:
        typeof options?.min === 'number'
          ? { message: t('yup.number.min', { min: options.min }), value: options.min }
          : options?.min,
      minLength:
        typeof options?.minLength === 'number'
          ? { message: t('yup.string.min', { min: options.minLength }), value: options.minLength }
          : options?.maxLength,
      required: options?.required === true ? t('yup.mixed.required') : options?.required,
    });

  return { register, ...rest };
};
