import './ProductPage.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Accordion } from '../Accordion';
import { useAppContext } from '../AppContext';
import { Breadcrumb } from '../Breadcrumb';
import { Button, ButtonVariant } from '../Button';
import { CustomRadio } from '../CustomRadio';
import { t } from '../i18n/i18n';
import { Icon, IconName, IconSize } from '../Icon';
import { ProductTile } from '../ProductTile';
import { Section } from '../Section';
import { SizeGuide } from '../SizeGuide';
import { SkuAttributeFormGroup } from '../SkuAttributeFormGroup';
import { Slider } from '../Slider';
import { SliderE } from '../slider/SliderE';
import { SliderButton } from '../SliderButton';
import { SocialSharing } from '../SocialSharing';
import { Page } from './Page';

export interface ProductPageProps {}

export const ProductPage = (props: ProductPageProps) => {
  const product = b2x.useProduct({ populate: b2x.appConfig.api?.productPopulate });

  const colorsSliderNextButton = React.useRef<HTMLButtonElement>(null);
  const colorsSliderPrevButton = React.useRef<HTMLButtonElement>(null);

  const { isFooterCopyrightVisible, isHeaderFirstRowVisible } = useAppContext();

  const [taglieRef, scrollToTaglieRef] = b2x.useScrollTo();

  const immagineTecnicaAttributes = React.useMemo(
    () => product?.attributes?.filter((attribute) => attribute.typeCode.startsWith('EQS_IMMAGINETECNICA')),
    [product?.attributes]
  );

  const sizeChartContentCode = React.useMemo(() => {
    let sizeChartAttributeValue = product?.attributes?.find(
      (attribute) => attribute.typeCode === 'EQS_SIZECHARTTABLE'
    )?.value;
    if (sizeChartAttributeValue?.endsWith('.txt')) {
      sizeChartAttributeValue = sizeChartAttributeValue.substring(0, sizeChartAttributeValue.length - 4);
    }
    return sizeChartAttributeValue;
  }, [product?.attributes]);

  return (
    <Page noPaddingTop thingsToLoadBeforePageReady={[product]}>
      {product !== undefined && (
        <b2x.ProductContext product={product}>
          {(productContext) => (
            <div className="ProductPage">
              <b2x.AddToCartFormHelper product={product} scope="product">
                {({ fieldsHelper, formik, priceHelper, selectedProductVariant, selectedSku }) => (
                  <>
                    <Breadcrumb hiddenLastItem />
                    <Section className="PrimaryInformation g-0" container="fluid">
                      <b2x.EqualHeight>
                        <div className="main-slider">
                          <b2x.EqualHeightElement disable={['xs', 'sm']} name="product-info">
                            {selectedProductVariant.image && (
                              <SliderE
                                aspectRatio={b2x.appConfig.productImageAspectRatio}
                                sliderList={[
                                  selectedProductVariant.image,
                                  ...(selectedProductVariant.alternativeImages
                                    ? selectedProductVariant.alternativeImages
                                    : []),
                                ].map((img) => ({ src: img.src }))}
                              />
                            )}
                          </b2x.EqualHeightElement>
                        </div>
                        <div className="main-info bg-white p-3 pt-0">
                          <b2x.EqualHeightElement disable={['xs', 'sm']} name="product-info">
                            {selectedProductVariant.tags?.includes('EQUESTRO_NEW') && (
                              <div className="new-product-sticker text-uppercase d-inline-block extra-small mt-3 mt-md-0">
                                {t('misc.newProductSticker')}
                              </div>
                            )}
                            <div className="product-information-toolbar d-flex justify-content-between mb-2 mt-3">
                              {selectedProductVariant.name && (
                                <h1 className="fw-light h2 mb-0">{b2x.formatHtml(selectedProductVariant.name)}</h1>
                              )}
                              <div className="product-wishlist">
                                <b2x.WishlistButtonHelper product={product} sku={selectedSku}>
                                  {({ handleWishlistButtonClick, inWishlist }) => (
                                    <Button
                                      className=""
                                      iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 35 }}
                                      onClick={handleWishlistButtonClick}
                                      variant="blank"
                                    />
                                  )}
                                </b2x.WishlistButtonHelper>
                              </div>
                            </div>
                            {selectedProductVariant.code && (
                              <p className="text-secondary extra-small">
                                {t('product.code')}: {selectedProductVariant.code}
                              </p>
                            )}
                            {selectedProductVariant.descriptionShort && (
                              <div className="description">
                                {b2x.formatHtml(selectedProductVariant.descriptionShort)}
                              </div>
                            )}
                            <div className="product-price-container my-3">
                              <div className="mb-1">
                                <b2x.PriceBlock
                                  classNames={{ price: 'h4 mb-0', specialPrice: 'h4 mb-0' }}
                                  gap={3}
                                  priceHelper={priceHelper}
                                />
                              </div>
                              {b2x.appConfig.enableBestPrice && priceHelper.bestPriceValue && (
                                <div className="small">
                                  <b2x.BestPrice priceHelper={priceHelper} />
                                </div>
                              )}
                            </div>
                            {fieldsHelper.productVariants.formFields.length > 1 && (
                              <b2x.FormGroup {...fieldsHelper.productVariants.formGroup} label={undefined}>
                                {selectedProductVariant.attributes &&
                                  selectedProductVariant.attributes.map(
                                    (attribute) =>
                                      attribute.typeCode === 'EQS_COLOR' && (
                                        <div className="form-label small fw-light mb-3" key={attribute.typeCode}>
                                          <span className="fw-normal">
                                            {attribute.title}: {attribute.value}
                                          </span>
                                        </div>
                                      )
                                  )}
                                <div className="d-flex">
                                  <SliderButton direction="left" innerRef={colorsSliderPrevButton} size="small" />
                                  <Slider
                                    navigation
                                    slideContentCentered
                                    sliderNextEl={colorsSliderNextButton}
                                    sliderPrevEl={colorsSliderPrevButton}
                                    slidesPerView={'auto'}
                                    spaceBetween={15}
                                  >
                                    {fieldsHelper.productVariants.formFields.map((formField) => (
                                      <SwiperSlide key={formField.productVariant.id}>
                                        <b2x.Radio {...formField.radio} inline>
                                          <CustomRadio imageUrl={formField.productVariant.image?.src} />
                                        </b2x.Radio>
                                      </SwiperSlide>
                                    ))}
                                  </Slider>
                                  <SliderButton direction="right" innerRef={colorsSliderNextButton} size="small" />
                                </div>
                              </b2x.FormGroup>
                            )}
                            {fieldsHelper.skuAttributes.map(({ attribute, formGroup, radios }, index) => (
                              <div key={attribute.title} ref={taglieRef}>
                                <SkuAttributeFormGroup
                                  attribute={attribute}
                                  className="border-top pt-2 pt-md-3 position-relative size-select-container"
                                  formGroup={formGroup}
                                  index={index}
                                  radios={radios}
                                  sizeChartContentCode={sizeChartContentCode}
                                  sliderResponsive={{}}
                                  slidesPerView={'auto'}
                                  spaceBetween={15}
                                />
                              </div>
                            ))}
                            <div className="d-grid mt-3 mt-md-2">
                              <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
                                // availabilityEmailNotificationButton={{ className: 'text-uppercase' }}
                                fieldsHelper={fieldsHelper}
                                selectedSku={selectedSku}
                                // submitButton={{ className: 'text-uppercase' }}
                              />
                            </div>
                            <div className="mt-3">
                              <b2x.InstallmentPaymentBanner amount={priceHelper.price} />
                            </div>
                            <div className="product-after-submit pb-3 mt-3">
                              <SocialSharing />
                              {sizeChartContentCode && (
                                <SizeGuide
                                  button={{
                                    className: 'text-dark fw-medium btn-sm text-decoration-none text-uppercase',
                                    iconStart: { name: 'size', size: 25 },
                                  }}
                                  className="d-block d-md-none"
                                  sizeChartContentCode={sizeChartContentCode}
                                />
                              )}
                            </div>
                          </b2x.EqualHeightElement>
                        </div>
                      </b2x.EqualHeight>
                      <b2x.Portal>
                        <b2x.ProductStickyFooter
                          fieldsHelper={fieldsHelper}
                          formik={formik}
                          isVisible={!isHeaderFirstRowVisible && !isFooterCopyrightVisible}
                          priceHelper={priceHelper}
                          product={product}
                          scrollToElement={scrollToTaglieRef}
                          selectedProductAttributes={selectedProductVariant.attributes?.filter(
                            (attr) => attr.typeCode === 'EQS_COLOR'
                          )}
                          selectedProductImage={selectedProductVariant.image}
                          selectedSku={selectedSku}
                        />
                      </b2x.Portal>
                    </Section>
                    <Section className="secondary-information" container="fluid">
                      <div className="row">
                        <div className="col-md-8 order-2 order-md-1">
                          <Accordion
                            accordionColClassName="p-0 px-md-3"
                            id="AccordionExample2"
                            itemBodyClassName="pt-0"
                            variant="b"
                          >
                            {product.attributes
                              ?.filter(
                                (attribute) =>
                                  attribute.typeCode &&
                                  ['EQS_SCHEDATECNICA', 'EQS_SIZEFIT', 'EQS_INFOCARE', 'EQS_COMPOSIZIONE'].includes(
                                    attribute.typeCode
                                  )
                              )
                              .map(
                                (attribute) =>
                                  attribute.typeCode && (
                                    <b2x.AccordionItem
                                      id={attribute.typeCode}
                                      key={attribute.typeCode}
                                      title={attribute.title ? attribute.title : attribute.typeCode}
                                    >
                                      {attribute.typeCode === 'EQS_SCHEDATECNICA' && immagineTecnicaAttributes ? (
                                        // Accordion - caso della scheda tecnica con immagini tecniche
                                        <div className="row">
                                          <div className="col-12 col-xl-6">{b2x.formatHtml(attribute.value)}</div>
                                          <div className="col-12 col-xl-6">
                                            <div className="row">
                                              {immagineTecnicaAttributes.map(
                                                (immagineTecnicaAttribute) =>
                                                  immagineTecnicaAttribute.typeCode && (
                                                    <div
                                                      className="col-12 mt-3 col-sm-4 mt-sm-0"
                                                      key={immagineTecnicaAttribute.typeCode}
                                                    >
                                                      <b2x.Image fluid src={immagineTecnicaAttribute.value} />
                                                    </div>
                                                  )
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      ) : (
                                        // Accordion - tutti gli altri casi filtrati precedentemente
                                        b2x.formatHtml(attribute.value)
                                      )}
                                    </b2x.AccordionItem>
                                  )
                              )}
                          </Accordion>
                          <div className="vstack gap-3 px-md-3 py-3 py-md-4">
                            {sizeChartContentCode && (
                              <div className="hstack gap-3">
                                <Icon name="size" size={30} />
                                {t('product.temporary.sizeGuide')}
                                <span>
                                  <SizeGuide
                                    button={{
                                      className: 'text-decoration-underline',
                                      variant: 'blank',
                                    }}
                                    className="d-inline"
                                    sizeChartContentCode={sizeChartContentCode}
                                  />
                                </span>
                              </div>
                            )}
                            <div className="hstack gap-3">
                              <Icon name="delivery" size={30} />
                              <span>{t('product.temporary.shipping')}</span>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4 order-1 order-md-2">
                          <div className="ps-lg-5">
                            {selectedProductVariant.image?.src && (
                              <div className="productImgSidebar m-auto">
                                <b2x.Image
                                  aspectRatio={b2x.appConfig.productImageAspectRatio}
                                  className="d-none d-md-block"
                                  fluid
                                  format={400}
                                  src={selectedProductVariant.image.src}
                                />
                              </div>
                            )}
                            {product.attributes?.find(
                              ({ typeCode }) => typeCode && typeCode.includes('EQS_ICONATECNICA')
                            ) && (
                              <div className="hstack gap-3 mt-3 mb-4 mb-md-5 justify-content-center">
                                {product.attributes.map(
                                  (attribute) =>
                                    attribute.typeCode &&
                                    attribute.typeCode.includes('EQS_ICONATECNICA') && (
                                      <div
                                        className="d-flex flex-column gap-2 align-items-center"
                                        key={attribute.typeCode}
                                      >
                                        <div className="product-icon">
                                          <b2x.Image src={attribute.value} />
                                        </div>
                                        {/* <span className="extra-small text-uppercase fw-light text-center">{attribute.title}</span> */}
                                      </div>
                                    )
                                )}
                              </div>
                            )}

                            {product.description && (
                              <div className="description my-3">
                                <h6 className="fw-light text-uppercase">{t('product.description')} </h6>
                                {b2x.formatHtml(product.description)}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </Section>
                  </>
                )}
              </b2x.AddToCartFormHelper>
              {productContext.selectedProductVariant.id && (
                <RelatedProducts productId={productContext.selectedProductVariant.id} />
              )}
            </div>
          )}
        </b2x.ProductContext>
      )}
    </Page>
  );
};

interface RelatedProductsProps {
  productId: string;
}

const RelatedProducts = ({ productId }: RelatedProductsProps) => {
  return (
    <b2x.RelatedProducts productId={productId}>
      {({ fetching, relatedProducts }) =>
        fetching ? (
          <b2x.Loading />
        ) : (
          relatedProducts &&
          relatedProducts.length > 0 && (
            <div className="related-products mt-5">
              <div className="p-0 p-xl-3">
                <h3 className="text-center text-uppercase h1 mb-4 mb-md-5 fw-semi-bold px-2">
                  {t('misc.relatedProducts')}
                </h3>
                <b2x.EqualHeight>
                  <b2x.Listing name="Product page - Related products" products={relatedProducts}>
                    <div className="slider-container">
                      <Slider
                        loop
                        navigation
                        responsive={{
                          lg: {
                            slidesPerView: 3.15,
                          },
                          md: {
                            slidesPerView: 2.15,
                          },
                          xl: {
                            slidesPerView: 4,
                          },
                        }}
                        sliderNextEl={'.related-products .swiper-button-next'}
                        sliderPrevEl={'.related-products .swiper-button-prev'}
                        slidesPerView={1.15}
                        spaceBetween={20}
                      >
                        {relatedProducts.map((relatedProduct, index) => (
                          <SwiperSlide key={relatedProduct.id}>
                            <ProductTile enableExtraInfo product={relatedProduct} />
                          </SwiperSlide>
                        ))}
                      </Slider>
                      <SliderButton direction="left" size="large" />
                      <SliderButton direction="right" size="large" />
                    </div>
                  </b2x.Listing>
                </b2x.EqualHeight>
              </div>
            </div>
          )
        )
      }
    </b2x.RelatedProducts>
  );
};
