import './SliderF.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Slider } from '../Slider';
import { SliderButton } from '../SliderButton';

export interface SliderFProps {
  sliderList?: b2x.contentTypes.ContentSectionList<{
    image?: b2x.contentTypes.ImageContentSection;
  }>;
}

export const SliderF = ({ sliderList }: SliderFProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
  return (
    <div className="SliderF">
      <div className={classnames(sliderList?.length && sliderList.length > 1 && 'slider-container')}>
        <Slider navigation pagination sliderNextEl={sliderNextButton} sliderPrevEl={sliderPrevButton} slidesPerView={1}>
          {sliderList &&
            sliderList.map(
              (slide) =>
                slide.image !== undefined && (
                  <SwiperSlide key={slide.contentSectionId}>
                    <b2x.ImageFromContent {...slide.image} fluid />
                  </SwiperSlide>
                )
            )}
        </Slider>
        <SliderButton direction="left" innerRef={sliderPrevButton} size="large" />
        <SliderButton direction="right" innerRef={sliderNextButton} size="large" />
      </div>
    </div>
  );
};
