import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { Button, ButtonVariant } from './Button';
import { CustomRadio } from './CustomRadio';
import { t } from './i18n/i18n';
import { IconName, IconSize } from './Icon';
import { SkuAttributeFormGroup } from './SkuAttributeFormGroup';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = ({ enableHover = true, ...otherProps }: ProductTileProps) => {
  return <b2x.ProductTile className={'card border-0 mb-3'} enableHover={enableHover} {...otherProps} />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = (props: ProductTileBasicInfoProps) => (
  <b2x.ProductTileBasicInfo {...props} customComponent={CustomProductTileBasicInfo} />
);

const CustomProductTileBasicInfo = ({
  handleLinkClick,
  product,
  productsPerRow,
  sku,
}: b2x.ProductTileBasicInfoProps) => {
  const {
    bestPriceDiscountPercentage,
    bestPriceValue,
    crossedOutPrice,
    discountPercentageDecimal,
    isPriceInRange,
    price,
  } = b2x.usePrice(product, sku?.id);

  return (
    <div className="product-tile-basic-info">
      <div className={classnames('card-header product-image p-0 border-0 bg-lighter')}>
        <b2x.router.Link onClick={handleLinkClick} to={product.url}>
          <b2x.EqualHeightElement name={`product-image-${productsPerRow}`}>
            <div className="d-flex align-items-center h-100">
              <b2x.Image
                alt={product.name}
                aspectRatio={b2x.appConfig.productImageAspectRatio}
                className="card-img-top"
                fluid
                format={800}
                src={product.image?.src}
              />
            </div>
          </b2x.EqualHeightElement>
        </b2x.router.Link>
        <div className="product-action">
          {b2x.appConfig.enableWishlist && (
            <b2x.WishlistButtonHelper product={product} sku={sku}>
              {({ handleWishlistButtonClick, inWishlist }) => (
                <Button
                  className=""
                  iconEnd={{ name: inWishlist ? 'wishlist-full' : 'wishlist', size: 30 }}
                  onClick={handleWishlistButtonClick}
                  size="large"
                  type="button"
                  variant="blank"
                />
              )}
            </b2x.WishlistButtonHelper>
          )}
        </div>
        {discountPercentageDecimal && <div className="promo h6">{`-${discountPercentageDecimal}%`}</div>}
        {product.tags?.includes('EQUESTRO_NEW') && (
          <div className="new-product-sticker text-uppercase extra-small">{t('misc.newProductSticker')}</div>
        )}
      </div>
      <div className="card-body border-0 p-2">
        <div className="d-flex flex-column flex-md-row justify-content-between mb-1">
          <div className="product-title">
            <b2x.EqualHeightElement name={`product-row-${productsPerRow}`}>
              <h6 className="card-title fw-light mb-0">
                <b2x.router.Link className="text-decoration-none text-black" onClick={handleLinkClick} to={product.url}>
                  {b2x.formatHtml(product.name)}
                </b2x.router.Link>
              </h6>
            </b2x.EqualHeightElement>
          </div>
          <b2x.PriceBlock
            alignItems={{ md: 'end', xs: 'center' }}
            classNames={{ crossedOutPrice: 'small', price: 'small' }}
            direction={{ md: 'column', xs: 'row' }}
            gap={{ md: 0, xs: 2 }}
            hiddenDiscountPercentage
            priceHelper={{
              bestPriceDiscountPercentage: bestPriceDiscountPercentage,
              bestPriceValue: bestPriceValue,
              crossedOutPrice: crossedOutPrice,
              isPriceInRange: isPriceInRange,
              price: price,
            }}
          />
        </div>
        <b2x.Row className="justify-content-between align-items-start" gap={0}>
          {!product.giftCard && (
            <b2x.Col size={{ md: 'auto', xs: 12 }}>
              <div className="colors extra-small text-dark">
                {t('product.colorsCount', { count: (product.variantProducts?.length ?? 0) + 1 })}
              </div>
            </b2x.Col>
          )}
          {b2x.appConfig.enableBestPrice && (
            <b2x.Col size={{ md: '', xs: 12 }}>
              <div className="extra-small ps-md-3">
                <b2x.BestPrice
                  priceHelper={{
                    bestPriceDiscountPercentage: bestPriceDiscountPercentage,
                    bestPriceValue: bestPriceValue,
                    crossedOutPrice: crossedOutPrice,
                    price: price,
                  }}
                />
              </div>
            </b2x.Col>
          )}
        </b2x.Row>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: b2x.ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo {...props} customComponent={CustomProductTileExtraInfo} />
);

const CustomProductTileExtraInfo = ({
  fieldsHelper,
  product,
  selectedProductVariant,
  selectedSku,
}: ProductTileExtraInfoProps) => {
  const { inWishlist } = b2x.useWishlistButtonHelper({
    product: selectedProductVariant ?? product,
    sku: selectedSku,
  });
  const colorsSliderNextButton = React.useRef<HTMLButtonElement>(null);
  const colorsSliderPrevButton = React.useRef<HTMLButtonElement>(null);
  const currentBreakpoint = b2x.useBreakpoint();
  return (
    <div className="product-tile-extra-info p-2">
      <div className="card-footer border-0 p-0 bg-white">
        {fieldsHelper.productVariants.formFields.length > 1 && (
          <b2x.FormGroup {...fieldsHelper.productVariants.formGroup} label={undefined}>
            <div className="d-flex">
              <SliderButton direction="left" innerRef={colorsSliderPrevButton} size="small" />
              <Slider
                navigation
                responsive={{}}
                slideContentCentered
                sliderNextEl={colorsSliderNextButton}
                sliderPrevEl={colorsSliderPrevButton}
                slidesPerView={'auto'}
                spaceBetween={15}
              >
                {fieldsHelper.productVariants.formFields.map((formField) => (
                  <SwiperSlide key={formField.productVariant.id}>
                    <b2x.Radio {...formField.radio} inline>
                      <CustomRadio imageUrl={formField.productVariant.image?.src} />
                    </b2x.Radio>
                  </SwiperSlide>
                ))}
              </Slider>
              <SliderButton direction="right" innerRef={colorsSliderNextButton} size="small" />
            </div>
          </b2x.FormGroup>
        )}
        {fieldsHelper.skuAttributes.map(({ attribute, formGroup, radios }, index) => (
          <SkuAttributeFormGroup
            attribute={attribute}
            formGroup={formGroup}
            index={index}
            key={attribute.title}
            radios={radios}
            sliderResponsive={{}}
            slidesPerView={'auto'}
            spaceBetween={15}
          />
        ))}
      </div>
      {selectedSku?.state !== 'AVAILABLE' && b2x.appConfig.enableProductAvailabilityEmailNotification && (
        <div className="mb-2">
          <b className="small "> {inWishlist ? t('misc.availabilityNotification') : t('misc.productNotAvailable')}</b>
        </div>
      )}
      <div className="d-grid">
        <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
          availabilityEmailNotificationButton={{
            label: inWishlist
              ? t('form.addToCartForm.buttons.availabilityEmailNotification.remove')
              : b2x.untilBreakpoint('xs', currentBreakpoint)
              ? t('form.addToCartForm.buttons.availabilityEmailNotification.addShortLabel')
              : t('form.addToCartForm.buttons.availabilityEmailNotification.addLongLabel'),
          }}
          fieldsHelper={fieldsHelper}
          selectedSku={selectedSku}
        ></b2x.AddToCartFormButton>
      </div>
      {/* <div className="d-grid">
        <b2x.AddToCartFormButton<ButtonVariant, IconName, IconSize>
          availabilityEmailNotificationButton={{ className: 'text-uppercase' }}
          fieldsHelper={fieldsHelper}
          selectedSku={selectedSku}
          submitButton={{ className: 'text-uppercase' }}
        />
      </div> */}
    </div>
  );
};
